import React from "react";

function TabItem({ count, title, value, onPress }) {
  return (
    <div
      className={count === value ? "app_tab_item_active" : "app_tab_item"}
      onClick={onPress}
    >
      <dev className="tab_circle_box">
        <div className="tab_circle_text">{count}</div>
      </dev>

      <div className="tab_taxt">{title}</div>
    </div>
  );
}

export default TabItem;
