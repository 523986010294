import React from "react";
import useColorStore from "../../../../store/color_store";
import useSearchColorStore from "../../../../store/search_store";

function PanelThreeSearchSubCtg() {
  const subCategories = useSearchColorStore((state) => state.searchColors);
  const selectedColors = useColorStore((state) => state.currentColors);
  const addColor = useColorStore((state) => state.addColor);
  const removeColor = useColorStore((state) => state.removeColor);

  const selectColor = (value) => {
    if (selectedColors.length > 0) {
      const index = selectedColors.findIndex((i) => i.id === value.id);
        console.log(`-----------selectedColors ${index}`);
    if (index !== -1) {
        removeColor(value);
      } else {
        addColor(value);
      }
    } else {
      addColor(value);
    }
  };

  return (
    <div className="panel-three-subcategory-body">
      <div className="panel-three-subcategory-grid-list">
        {subCategories.map((sb) => (
          <div
            key={sb.id}
            className="panel-three-grid-item"
            style={{
              backgroundColor: sb.color_code,
            }}
            onClick={() => {
              selectColor(sb);
            }}
          >
            <div className="panel-three-grid-item-title">{sb.title}</div>

            {selectedColors.length > 0 && (
              <>
                {selectedColors.map((i) => (
                  <div>
                    {sb.id === i.id && (
                      <div className="panel-three-grid-checkmark">
                        <i className="far fa-check"></i>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}

            <div className="panel-three-grid-code-section">
              <div className="panel-three-grid-item-code">{sb.color_code}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PanelThreeSearchSubCtg;
