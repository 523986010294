import React from "react";
import PanelThreeSearch from "./compnents/PanelThreeSearch";
import PanelThreeColorList from "./compnents/PanelThreeColorList";
import PanelThreeSelectedColors from "./compnents/PanelThreeSelectedColors";
import PanelThreeSubCtg from "./compnents/PanelThreeSubCtg";
import useSearchColorStore from "../../../store/search_store";
import PanelThreeSearchSubCtg from "./compnents/PanelThreeSearchSubCtg";
import useTabStore from "../../../store/tab_store";

function PanelThree() {
  const isMobile = useTabStore((state) => state.isMobile);
  const isSearch = useSearchColorStore((state) => state.isSearch);
  return (
    <div className="body">
      {isMobile && <PanelThreeSelectedColors />}

      <PanelThreeSearch />
      <PanelThreeColorList />
      <div className="sub-category-section">
        {!isMobile && <PanelThreeSelectedColors />}

        {isSearch ? <PanelThreeSearchSubCtg /> : <PanelThreeSubCtg />}
      </div>
    </div>
  );
}

export default PanelThree;
