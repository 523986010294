import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import usePreviewStore from "../../../store/preview_store";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { Button } from "react-bootstrap";
import useOrderStore from "../../../store/order_store";
import { CanvasPreview } from "../CanvasPreview";

const ImagePreview = forwardRef((props, ref) => {
  const [crop, setCrop] = useState({
    unit: "%",
    width: 60,
    height: 60,
    x: 20,
    y: 20,
    aspect: 9 / 9,
  });
  const [isCrop, setIsCrop] = useState(false);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const onUploadData = useOrderStore((state) => state.onUploadData);
  const setUploadImageUrl = useOrderStore((state) => state.setUploadImageUrl);
  const onSetPreviewSize = useOrderStore((state) => state.onSetSize);
  const imageUploaded = useOrderStore((state) => state.uploadImage);

  const [output, setOutput] = useState(null);

  useEffect(() => {
    onUploadData([]);

    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width; // * pixelRatio;
    canvas.height = crop.height; // * pixelRatio;

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    if (completedCrop?.width * completedCrop?.height === 0) {
      alert("crop error! crop width or height must not 0");
    } else {
      onUploadData([
        ctx.getImageData(0, 0, completedCrop?.width, completedCrop?.height),
      ]);
      let imageData = ctx.getImageData(
        0,
        0,
        completedCrop?.width,
        completedCrop?.height
      );

      let image = canvas.toDataURL("image/png"); // Convert canvas content to PNG image
      setUploadImageUrl(image);
      // onSetPreviewSize({
      //   width: completedCrop.width,
      //   height: completedCrop.height,
      // });
    }
  }, [completedCrop]);

  useImperativeHandle(ref, () => ({
    onCanvas: () => {
      if (!isCrop) {
        if (completedCrop) {
          cropImageNow();
        } else {
          cropOnFirstLoad();
        }
      }
    },
  }));

  const cropOnFirstLoad = () => {
    let image = imgRef.current;
    let canvas = previewCanvasRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    let ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = (image.width * (crop.width + crop.x)) / 100;
    canvas.height = (image.height * crop.height) / 100;

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      (image.naturalWidth * crop.x) / 200,
      (image.naturalHeight * crop.y) / 100,
      (image.naturalWidth * (crop.width + crop.x)) / 100,
      (image.naturalHeight * crop.height) / 100,
      // canvas.width * scaleX,
      // canvas.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );
    onUploadData([ctx.getImageData(0, 0, canvas.width, canvas.height)]);
    let imageDataUrl = canvas.toDataURL("image/png"); // Convert canvas content to PNG image
    setUploadImageUrl(imageDataUrl);
    // onSetPreviewSize({ width: canvas.width, height: canvas.height });
  };

  const cropImageNow = () => {
    const canvas = previewCanvasRef.current;
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    onUploadData([ctx.getImageData(0, 0, canvas.width, canvas.height)]);
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
    let image = canvas.toDataURL("image/png"); // Convert canvas content to PNG image
    setUploadImageUrl(image);
    //  onSetPreviewSize({ width: crop.width, height: crop.height });
  };

  return (
    <div className="panel-preview">
      <div className="photo_edit_frame col-md-8">
        <div className="frame_header">
          <div>
            <Button
              className="link"
              onClick={() => setRotate((rotate + 90) % 360)}
            >
              <span className="pad_right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.1921 14.302L29.3323 14.4268L33.314 18.4394L33.4379 18.5807C33.854 19.1201 33.854 19.88 33.4379 20.4194L33.314 20.5607L33.1738 20.6855C32.6385 21.1049 31.8845 21.1049 31.3492 20.6855L31.209 20.5607L29.823 19.1625C29.6589 20.879 29.133 22.5226 28.2842 24.0013C26.1707 27.683 22.2701 30 17.9704 30C14.2651 30 10.8382 28.2816 8.59559 25.3998C8.08829 24.748 8.20144 23.8051 8.84831 23.2939C9.49518 22.7826 10.4308 22.8967 10.9381 23.5485C12.6223 25.7127 15.1895 27 17.9704 27C21.1965 27 24.1207 25.263 25.7073 22.499C26.3122 21.4454 26.6979 20.2812 26.8399 19.062L25.3506 20.5607L25.2103 20.6855C24.6264 21.143 23.7821 21.1014 23.2455 20.5607C22.709 20.02 22.6677 19.1691 23.1217 18.5807L23.2455 18.4394L27.2273 14.4268L27.3675 14.302C27.9028 13.8826 28.6568 13.8826 29.1921 14.302ZM18.1023 6C21.8075 6 25.2345 7.7184 27.4771 10.6002C27.9844 11.252 27.8712 12.1949 27.2244 12.7061C26.5775 13.2174 25.6419 13.1033 25.1346 12.4515C23.4504 10.2873 20.8832 9 18.1023 9C14.8762 9 11.952 10.737 10.3653 13.501C9.98811 14.1581 9.6961 14.8583 9.49531 15.588L10.6494 14.4268L10.7897 14.302C11.3736 13.8445 12.2179 13.8861 12.7545 14.4268C13.291 14.9675 13.3323 15.8184 12.8783 16.4068L12.7545 16.5481L8.77272 20.5607L8.6325 20.6855C8.09724 21.1049 7.34318 21.1049 6.80792 20.6855L6.6677 20.5607L2.68597 16.5481L2.56214 16.4068C2.14595 15.8674 2.14595 15.1075 2.56214 14.5681L2.68597 14.4268L2.82619 14.302C3.36145 13.8826 4.11551 13.8826 4.65077 14.302L4.79099 14.4268L6.3582 16.0067C6.59322 14.5911 7.07764 13.2371 7.7885 11.9987C9.90197 8.31703 13.8026 6 18.1023 6Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              Rotate
            </Button>
            <Button
              className="link ml-4"
              onClick={() => {
                onUploadData([]);
                setRotate(0);
                setIsCrop(false);
                setCrop({
                  unit: "%",
                  width: 60,
                  height: 60,
                  x: 20,
                  y: 20,
                  aspect: 9 / 9,
                });
              }}
            >
              <span className="pad_right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M19.5001 28.5C24.4706 28.4999 28.5 24.4705 28.5 19.5C28.5 14.5294 24.4706 10.5 19.5 10.5C14.746 10.5 10.8529 14.186 10.5227 18.856L13.9393 15.4393C14.5251 14.8536 15.4749 14.8536 16.0607 15.4393C16.6464 16.0251 16.6464 16.9749 16.0607 17.5607L10.0607 23.5607C9.77936 23.842 9.39782 24 9 24C8.60218 24 8.22064 23.842 7.93934 23.5607L1.93934 17.5607C1.35355 16.9749 1.35355 16.0251 1.93934 15.4393C2.52513 14.8536 3.47487 14.8536 4.06066 15.4393L7.51505 18.8937C7.83081 12.548 13.0759 7.5 19.5 7.5C26.1274 7.5 31.5 12.8726 31.5 19.5C31.5 26.1274 26.1275 31.4999 19.5001 31.5C19.5001 31.5 19.5002 31.5 19.5001 31.5C18.6717 31.5 18 30.8284 18 30C18 29.1716 18.6717 28.5 19.5001 28.5C19.5002 28.5 19.5001 28.5 19.5001 28.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              Reset
            </Button>
          </div>
          <Button
            className="link"
            onClick={() => {
              setIsCrop(true);
              cropImageNow();
            }}
          >
            <span className="pad_right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="36"
                viewBox="0 0 37 36"
                fill="none"
              >
                <path
                  d="M9.3335 9C9.3335 10.6569 7.99035 12 6.3335 12C4.67664 12 3.3335 10.6569 3.3335 9C3.3335 7.34315 4.67664 6 6.3335 6C7.99035 6 9.3335 7.34315 9.3335 9Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.4252 11.9653C24.2676 9.9808 21.4002 9.98079 20.2425 11.9653L15.9661 19.2964L14.1424 16.3122C12.9726 14.398 10.1925 14.398 9.02272 16.3122L3.4473 25.4356C2.22566 27.4347 3.66436 30 6.00715 30L15.3335 30L30.7223 30C33.0377 30 34.4803 27.4883 33.3137 25.4884L25.4252 11.9653Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            Crop
          </Button>
        </div>

        {isCrop ? (
          <div className="uploaded_photo">
            <img src={output} />
          </div>
        ) : (
          <div className="uploaded_photo">
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            >
              <img
                className="profile_image"
                alt="Profile"
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                }}
                height="500px"
                width="800px"
                src={imageUploaded}
                ref={imgRef}
              ></img>
            </ReactCrop>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
                display: "none",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default ImagePreview;
