import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import PanelCommonToolbar from "../panel/compnents/PanelCommonToolbar";
import PanelFour from "../panel/PanelFour";
import useTabStore from "../../../store/tab_store";
import { ToastContainer, toast } from "react-toastify";
import PanelCommonToolbarMobile from "../panel/compnents/PanelCommonToolbarMobile";
import ScreenshotLeftBottom from "../panel/compnents/ScreenshotLeftBottom";

function StepThree() {
  const isMobile = useTabStore((state) => state.isMobile);

  const toastId = useRef();
  const notify = () => toast("Wait for processing..!");

  return (
    <div className="panel-four">
      <Row>
        <Col>
          <Row>
            <Col>
              {isMobile ? (
                <PanelCommonToolbarMobile
                  title="Visualize your room in colors of your choice"
                  nextBtnText="Find a Store"
                  onNext={() => {}}
                />
              ) : (
                <PanelCommonToolbar
                  title="Visualize your room in colors of your choice"
                  nextBtnText="Find a Store"
                  onNext={() => {}}
                />
              )}
            </Col>
          </Row>
          <Row>
            <PanelFour notify={notify} />
          </Row>
        </Col>
      </Row>

      <ToastContainer
        ref={toastId}
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default StepThree;
