import React from "react";
import useTabStore from "../../../../store/tab_store";
import useOrderStore from "../../../../store/order_store";
import { useDropzone } from "react-dropzone";

function PanelOneItemT({ onDrop, accept, open }) {
  const onUpload = useTabStore((state) => state.onUpload);
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ accept, onDrop });
  return (
    <div {...getRootProps({ className: "dropzone panel-item" })}>
      <input {...getInputProps()} />

      <div className="panel-item-box" onClick={open}>
        <p className="panel-item-box-titel">JPG, PNG, HEIC, etc</p>
        <p className="panel-item-box-subtitle">Max. File Size: 15 MB</p>
      </div>
      <div className="panel-section">
        <p className="panel-item-text">Upload your own image</p>
        <p className="panel-item-subtitle">
          Bring your project to life with an image of your own room{" "}
        </p>
      </div>
    </div>
  );
}

export default PanelOneItemT;
