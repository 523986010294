import React from "react";
import useTabStore from "../../../../store/tab_store";
import usePreviewStore from "../../../../store/preview_store";

function PanelCommonToolbar({ title, nextBtnText, onNext }) {
  const onBack = useTabStore((state) => state.chnageIndex);
  const onPreview = usePreviewStore((state)=>state.onUpload)
  const index = useTabStore((state) => state.index);
  return (
    <div className="panel-two-toolbar">
      {/** upload btn */}
      <div
        className="panel-two-back"
        onClick={() => {
          if (index !== 1) {
            if (index === 2) {
              onPreview(false)
            }
            onBack(index - 1);
            
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M12.5 5L7.5 10L12.5 15"
            stroke="#D9121F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className="panel-two-back-text">Back</div>
      </div>

      {/** upload btn end */}
      {/** title */}
      <p className="panel-two-title">{title}</p>
      {/** pick color btn */}

      <div className="panel-two-color-btn" onClick={onNext}>
        <div className="panel-two-color-btn-text">{nextBtnText}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M8 15L13 10L8 5"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>{" "}
      </div>
      {/** pick color btn end */}
    </div>
  );
}

export default PanelCommonToolbar;
