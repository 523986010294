import React from "react";

function ScreenshotLeftBottom() {
  return (
    <div id="water-mark" className="screenshot-bottom-section">
      <div>
        <div className="screenshot-bottom-contact-group">
          <div className="s_icon_area">
            <i className="fal fa-phone"></i>
          </div>
          <div className="screenshot-bottom-text-double">
            {/* <div className="s_option_name">Call Us</div> */}
            <div className="s_option_name">+971 4 256 5474</div>
          </div>
        </div>
        <div className="screenshot-bottom-contact-group">
          <div className="s_icon_area">
            <i className="fal fa-envelope-open-text"></i>
          </div>
          <div className="screenshot-bottom-text-double">
            {/* <div className="s_option_name">Send E-mail</div> */}
            <div className="s_option_name"> info@emiratespaint.com</div>
          </div>
        </div>
        <div className="screenshot-bottom-contact-group">
          <div className="s_icon_area">
            <i className="fal fa-location"></i>
          </div>
          <div className="screenshot-bottom-text-double">
            {/* <div className="s_option_name">Location</div> */}
            <div className="s_option_name"> P.O Box 1, 14a St, Umm Ramool</div>
            <div className="s_option_name"> Dubai, United Arab Emirates</div>
          </div>
        </div>
      </div>

      <div className="water-mark-image"></div>
    </div>
  );
}

export default ScreenshotLeftBottom;
