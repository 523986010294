import React from "react";
import useColorStore from "../../../../store/color_store";
import useTabStore from "../../../../store/tab_store";
import useOrderStore from "../../../../store/order_store";

function PanelThreeSelectedColors() {
  const index = useTabStore((state) => state.index);
  const onNext = useTabStore((state) => state.chnageIndex);
  const selectedColors = useColorStore((state) => state.currentColors);
  const removeColor = useColorStore((state) => state.removeColor);
  const selcetedColor = useOrderStore((state) => state.selectedColor);
  const setSelectedColor = useOrderStore((state) => state.setSelectedColor);

  const removeHandler = (color) => {
    removeColor(color);
  };
  const selectedHandler = (color) => {
    
    console.log("-----------select", index);
    if (index === 3) {
      console.log("-----------select", color);
      setSelectedColor(color);
    }
  };
  return (
    <div className="panel-selected-colors">
      {/**  count */}
      <div className="panel-total-count-section">
        <p className="panel-total-text">Saved Colors</p>
        <p className="panel-total-count-text" id="panel-total-count-text">
          ( {selectedColors.length} )
        </p>
      </div>

      {/**  items */}

      <div className="panel-selected-colors-body">
        {selectedColors.map((c) => (
          <div
            key={c.color_code}
            className="panel-selected-color-item"
            onClick={() => {
              const data = c;
              data.isUsed = true;
              selectedHandler(data);
            }}
          >
            <div className="panel-color-content">
              <div className="panel-color-avater-section">
                <span
                  className="panel-color-avater"
                  style={{ backgroundColor: c.color_code }}
                ></span>

                <div
                  className={
                    selcetedColor.color_code === c.color_code
                      ? "avater-selected-checkmark selectd-avater-active"
                      : "avater-selected-checkmark"
                  }
                >
                  <i className="far fa-check"></i>
                </div>
              </div>

              <div>
                <div className="panel-color-title">{c.title}</div>
                <div className="panel-color-subtitle">{c.color_code}</div>
              </div>
            </div>

            <div
              className="panel-color-remove"
              id="panel-color-remove"
              onClick={() => {
                removeHandler(c);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 5H7V4C7 3.46957 7.21071 2.96086 7.58579 2.58579C7.96086 2.21071 8.46957 2 9 2H15C15.5304 2 16.0391 2.21071 16.4142 2.58579C16.7893 2.96086 17 3.46957 17 4V5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6C21 6.26522 20.8946 6.51957 20.7071 6.70711C20.5196 6.89464 20.2652 7 20 7H19V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V7H4C3.73478 7 3.48043 6.89464 3.29289 6.70711C3.10536 6.51957 3 6.26522 3 6C3 5.73478 3.10536 5.48043 3.29289 5.29289C3.48043 5.10536 3.73478 5 4 5ZM7 7V20H17V7H7ZM9 5H15V4H9V5ZM9 9H11V18H9V9ZM13 9H15V18H13V9Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        ))}

        <div>
          {index === 3 && (
            <div
              id="panel-add-color-btn"
              className="panel-add-color-btn"
              onClick={() => {
                onNext(2);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.5 12C2.5 6.477 6.977 2 12.5 2C18.023 2 22.5 6.477 22.5 12C22.5 17.523 18.023 22 12.5 22C6.977 22 2.5 17.523 2.5 12ZM12.5 4C10.3783 4 8.34344 4.84285 6.84315 6.34315C5.34285 7.84344 4.5 9.87827 4.5 12C4.5 14.1217 5.34285 16.1566 6.84315 17.6569C8.34344 19.1571 10.3783 20 12.5 20C14.6217 20 16.6566 19.1571 18.1569 17.6569C19.6571 16.1566 20.5 14.1217 20.5 12C20.5 9.87827 19.6571 7.84344 18.1569 6.34315C16.6566 4.84285 14.6217 4 12.5 4Z"
                  fill="#D9121F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.5 7C13.5 6.73478 13.3946 6.48043 13.2071 6.29289C13.0196 6.10536 12.7652 6 12.5 6C12.2348 6 11.9804 6.10536 11.7929 6.29289C11.6054 6.48043 11.5 6.73478 11.5 7V11H7.5C7.23478 11 6.98043 11.1054 6.79289 11.2929C6.60536 11.4804 6.5 11.7348 6.5 12C6.5 12.2652 6.60536 12.5196 6.79289 12.7071C6.98043 12.8946 7.23478 13 7.5 13H11.5V17C11.5 17.2652 11.6054 17.5196 11.7929 17.7071C11.9804 17.8946 12.2348 18 12.5 18C12.7652 18 13.0196 17.8946 13.2071 17.7071C13.3946 17.5196 13.5 17.2652 13.5 17V13H17.5C17.7652 13 18.0196 12.8946 18.2071 12.7071C18.3946 12.5196 18.5 12.2652 18.5 12C18.5 11.7348 18.3946 11.4804 18.2071 11.2929C18.0196 11.1054 17.7652 11 17.5 11H13.5V7Z"
                  fill="#D9121F"
                />
              </svg>
              <div className="panel-add-text-btn">Add More Color</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PanelThreeSelectedColors;
