import { create } from "zustand";
import { devtools } from "zustand/middleware";

const zoomingStore = (set) => ({
  zooming: true,

  setZooming: (value) => {
    return set((state) => ({
      zooming: value,
    }));
  },
});

export const useZoomStore = create(devtools(zoomingStore));

export default useZoomStore;
