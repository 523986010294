import { create } from "zustand";

import { devtools } from "zustand/middleware";

const orderStore = (set) => ({
  colors: [],
  image: {},

  category: {},
  colorCategory: {},
  selectedColor: {},
  previewSize: { width: "", height: "" },

  selectMask: { width: "", height: "" },
  uploadImage: null,
  uploadImageUrl: null,
  uploadData: [],

  createOrder: (value) =>
    set((state) => ({
      colors: value.colors,
      image: value.image,
      uploadImage: value.uploadImage,
      category: value.category,
      colorCategory: value.colorCategory,
      selectedColor: value.selectedColor,
      selectMask: value.selectMask,
    })),
  setImage: (value) => set((state) => ({ image: value })),
  setColors: (value) => set((state) => ({ colors: value })),
  setSelectedColor: (value) => set((state) => ({ selectedColor: value })),
  setUploadFile: (value) => set((state) => ({ uploadImage: value })),
  setUploadImageUrl: (value) => set((state) => ({ uploadImageUrl: value })),
  setCategory: (value) => set((state) => ({ category: value })),
  setColorCategory: (value) => set((state) => ({ colorCategory: value })),
  setSeclecMask: (value) => set((state) => ({ selectMask: value })),
  onUploadData: (value) => set((state) => ({ uploadData: value })),
  onSetSize: (value) => set((state) => ({ previewSize: value })),
});

const useOrderStore = create(devtools(orderStore));

export default useOrderStore;
