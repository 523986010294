import React from "react";
import useTabStore from "../../../../store/tab_store";

function PanelOneItem() {
  const onOurImageAction = useTabStore((state) => state.onOurImage);

  const onOurImage = () => {
    onOurImageAction(true);
  };
  return (
    <div className="panel-item" onClick={onOurImage}>
      <div>
        <img
          className="panel-item-image"
          src={require("../../../../assets/images/categories/living.jpg")}
        />
      </div>
      <div className="panel-section">
        <p className="panel-item-text">Use Our Images</p>
        <p className="panel-item-subtitle">
          Pick a relevant image that represents your own room
        </p>
      </div>
    </div>
  );
}

export default PanelOneItem;
