import ls from "local-storage";

//Auth module
import PrivateRoute from "./PrivateRoute";
import OpenRoute from "./OpenRoute";

// import external modules
import React, { Component, lazy } from "react";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import HomeOld from "../pages/HomeOld";
import FullLayout from "../layouts/FullLayout";

class OpenRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Routes>
          {/* <OpenRoute exact path="/" component={Home}></OpenRoute> */}

          <Route
            exact
            path="/"
            element={
              <FullLayout>
                <Home />
              </FullLayout>
            }
          ></Route>

          {/* <Redirect path="*" to="/" /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Routes>
          {/* <PrivateRoute exact path="/" component={Home}></PrivateRoute> */}
          <Route
            exact
            path="/"
            element={
              <FullLayout>
                <Home />
              </FullLayout>
            }
          ></Route>

          {/* <Redirect path="*" to="/" /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
