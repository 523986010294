import React from "react";
import TabItem from "./TabItem";
import useTabStore from "../../store/tab_store";

function HeaderTab() {
  const index = useTabStore((state) => state.index);
  const onChange = useTabStore((state) => state.chnageIndex);
  return (
    <div className="app_tabs">
      {/* tab item 1 */}
      <TabItem
        count={1}
        title="Choose an Image"
        value={index}
        onPress={() => {
         // onChange(1);
        }}
      />
      {/* tab item 2 */}
      <TabItem
        count={2}
        title="Choose a Color"
        value={index}
        onPress={() => {
          //onChange(2);
        }}
      />
      {/* tab item 3 */}
      <TabItem
        count={3}
        title="Visualize"
        value={index}
        onPress={() => {
          //onChange(3);
        }}
      />
    </div>
  );
}

export default HeaderTab;
