import React, { useCallback, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import PanelTwoToolBar from "./compnents/PanelTwoToolBar";
import CategoryList from "./CategoryList";
import CategoryDetails from "./CategoryDetails";
import useTabStore from "../../../store/tab_store";
import useOrderStore from "../../../store/order_store";
import DropImageUpload from "../drop_image_upload";
import usePreviewStore from "../../../store/preview_store";
import ImagePreview from "./ImagePreview";
import PanelTwoToolBarMobile from "./compnents/PanelTwoToolBarMobile";

function PanelTwo() {
  const previewCanvasRef = useRef(null);
  const isMobile = useTabStore((state) => state.isMobile);
  const isUpload = useTabStore((state) => state.isUpload);
  const isPreview = usePreviewStore((state) => state.isUpload);
  const onUploadImage = useOrderStore((state) => state.setUploadFile);
  const onPreview = usePreviewStore((state) => state.onUpload);

  const onPreviewCanvas = () => {
    if (previewCanvasRef) {
      if (previewCanvasRef.current !== null) {
        previewCanvasRef.current.onCanvas();
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    onUploadImage(URL.createObjectURL(acceptedFiles[0]));
    onPreview(true);
  }, []);

  const onOpen = (e) => {
    onUploadImage(URL.createObjectURL(e.target.files[0]));
    onPreview(true);
  };

  return (
    <Row className="panelTwo">
      <Col>
        <Row>
          <Col>
            {isMobile ? (
              <PanelTwoToolBarMobile onPreviewCanvas={onPreviewCanvas} />
            ) : (
              <PanelTwoToolBar onPreviewCanvas={onPreviewCanvas} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!isUpload ? (
              <div className="panel-two-body-content">
                <CategoryList />
                <CategoryDetails />
              </div>
            ) : (
              <div className="panel-two-body-content">
                {isPreview ? (
                  <ImagePreview ref={previewCanvasRef} />
                ) : (
                  <DropImageUpload onDrop={onDrop} accept={"image/*"} />
                )}
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PanelTwo;
