import React, { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";

import { Redirect, Route, Link, useParams } from "react-router-dom";
import Logo from "../components/Logo";

// custom component
import LeftSidebar from "./LeftSidebar";

const Header = (props) => {
  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(false);
  const openExpandSearchBar = () => {
    setExpandSearchBar(true);
  };
  const closeExpandSearchBar = () => {
    setExpandSearchBar(false);
  };

  // topbar
  const [topBar, setTopBar] = useState(true);
  const closeTopBar = () => {
    setTopBar(false);
  };

  // sticky header

  const [scroll, setScroll] = useState(0);

  // left menus
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });

  return (
    <header>
      <>
        <section className={scroll ? "app_header to_fixed" : "app_header"}>
          {expandSearchBar && (
            <div className="expand_searchbox">
              <input
                className="form-control expand_input"
                placeholder="Search..."
                type="text"
              />
              <i
                onClick={closeExpandSearchBar}
                className="fal fa-times close_search_bar"
              ></i>
            </div>
          )}
          {/***
           *
           *
           *
           *
           */}
          {/* topBar && (
            <div className="header_topbar">
              Premium Quality Paint Made in UAE
              <i
                onClick={closeTopBar}
                className="fal fa-times text-black top_remove"
              ></i>
            </div>
          )*/}

          <div className="app_header_menu">
            <div className="menu-group mbnavld">
              {showLeftSidebar == false ? (
                <Link
                  className="d-lg-none mbnavlink"
                  onClick={() => {
                    setShowLeftSidebar(true);
                    window.scrollbars = false;
                    // setTimeout(function () {
                    //   setShowLeftSidebar(false);
                    // }, 500);
                  }}
                  to="#"
                >
                  <i className="far fa-bars"></i>
                </Link>
              ) : (
                <Link
                  className="d-lg-none mnlogo"
                  onClick={() => {
                    setShowLeftSidebar(false);
                    // setTimeout(function () {
                    //   setShowLeftSidebar(true);
                    // }, 500);
                  }}
                  to="#"
                >
                  <i className="fas fa-times"></i>
                </Link>
              )}
              <Link to="https://www.emiratespaints.com/" target="_parent">
                <Logo />
              </Link>
            </div>

            <div className="menu-group d-none mainvan d-lg-flex">
              <a href="https://www.emiratespaints.com/product-category/red/">
                Colors
              </a>
              <a href="https://www.emiratespaints.com/products/">Products</a>
              <a href="https://www.emiratespaints.com/inspirations/">
                Inspiration
              </a>
              <Link to="#" className="active">
                Visualizer
              </Link>
              <a href="https://www.emiratespaints.com/find-store/">
                Find A Store
              </a>
              <a href="https://www.emiratespaints.com/book-a-painter/">
                Book A Painter
              </a>
              <a href="https://www.emiratespaints.com/about-us/">About</a>
            </div>

            {/* <div className="menu-group search-login">
              <Link className="active" to="#" onClick={openExpandSearchBar}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7.61667 13.9833C11.1329 13.9833 13.9833 11.1329 13.9833 7.61667C13.9833 4.10045 11.1329 1.25 7.61667 1.25C4.10045 1.25 1.25 4.10045 1.25 7.61667C1.25 11.1329 4.10045 13.9833 7.61667 13.9833Z" stroke="#d9121f" stroke-width="1.5" stroke-miterlimit="10"></path>
									<path d="M18.75 18.75L11.9917 11.9917" stroke="#d9121f" stroke-width="1.5" stroke-miterlimit="10"></path>
                </svg>
              </Link>
              <Link className="active" to="#">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-eng-ff="Jost">
                  <path d="M10 10.8166C12.6372 10.8166 14.775 8.67876 14.775 6.0416C14.775 3.40444 12.6372 1.2666 10 1.2666C7.36285 1.2666 5.22501 3.40444 5.22501 6.0416C5.22501 8.67876 7.36285 10.8166 10 10.8166Z" stroke="#d9121f" stroke-width="1.5" stroke-miterlimit="10" data-eng-ff="Jost"></path>
                  <path d="M1.25 19.5668L1.55833 17.8585C1.92258 15.8856 2.96645 14.1025 4.50859 12.8192C6.05074 11.5359 7.99371 10.8333 10 10.8335V10.8335C12.0087 10.834 13.9536 11.5389 15.496 12.8256C17.0385 14.1123 18.0809 15.8992 18.4417 17.8752L18.75 19.5835" stroke="#d9121f" stroke-width="1.5" stroke-miterlimit="10" data-eng-ff="Jost"></path>
                </svg>                
              </Link>
            </div> */}
          </div>
        </section>
        <LeftSidebar
          openLeftSidebar={showLeftSidebar}
          open={setShowLeftSidebar}
        />
      </>
    </header>
  );
};

export default Header;
