import React, { useCallback } from "react";
import PanelOneItem from "./compnents/PanelOneItem";
import PanelOneItemT from "./compnents/PanelOneItemT";
import usePreviewStore from "../../../store/preview_store";
import useOrderStore from "../../../store/order_store";
import useTabStore from "../../../store/tab_store";

function PanelOne() {

  const onUplaodPage = useTabStore((state)=>state.onUpload)
 const onUploadImage = useOrderStore((state) => state.setUploadFile);
 const onPreview = usePreviewStore((state) => state.onUpload);



 const onDrop = useCallback((acceptedFiles) => {
   onUploadImage(URL.createObjectURL(acceptedFiles[0]));
   onPreview(true);
   onUplaodPage(true)

 }, []);
  

  return (
    <div className="panelOne">
      <div className="body">
        <PanelOneItem />
        <div className="panel-item-or">Or</div>
        <PanelOneItemT onDrop={onDrop} accept={"image/*"}  />
      </div>
    </div>
  );
}

export default PanelOne;
