import React, { useRef, useState } from "react";
import ImageDrawToolBar from "./compnents/ImageDrawToolBar";
import Canvas from "../Canvas";
import useOrderStore from "../../../store/order_store";
import useColorStore from "../../../store/color_store";
import { number } from "prop-types";
import useTabStore from "../../../store/tab_store";
import { toPng, toPixelData } from "html-to-image";
import UploadCanvas from "../UploadCanvas";
import usePreviewStore from "../../../store/preview_store";
import ImageDrawToolBarMobile from "./compnents/ImageDrawToolBarMobile";
import ScreenshotLeftBottom from "./compnents/ScreenshotLeftBottom";
import useZoomStore from "../../../store/zooming_store";

function ImageDraw(props) {
  const isMobile = useTabStore((state) => state.isMobile);
  const index = useTabStore((state) => state.index);
  const image = useOrderStore((state) => state.image);
  const selectedColor = useOrderStore((state) => state.selectedColor);
  const colors = useColorStore((state) => state.currentColors);
  const uploadData = useOrderStore((state) => state.uploadData);
  const onNext = useTabStore((state) => state.chnageIndex);
  const onPreview = usePreviewStore((state) => state.onUpload);
  const canvasRef = useRef();
  const uploadCanvasRef = useRef();
  const setIsZooming = useZoomStore((state) => state.setZooming);

  const [loading, setLoading] = useState(false);
  const [drawTab, setDrawTab] = useState("Paint");

  const onChangeImage = () => {
    onNext(1);
    onPreview(false);
  };

  const onUndo = () => {
    if (uploadData.length > 0) {
      uploadCanvasRef.current.undo();
    } else {
      canvasRef.current.undo();
    }
  };
  const onReset = () => {
    if (uploadData.length > 0) {
      uploadCanvasRef.current.reset();
    } else {
      canvasRef.current.reset();
    }
  };
  const onRedo = () => {
    if (uploadData.length > 0) {
      uploadCanvasRef.current.redo();
    } else {
      canvasRef.current.redo();
    }
  };
  const onSave = () => {
    props.notify();
    onScreenShot();
  };

  const editImageTab = (tab) => {
    if (uploadCanvasRef.current !== undefined) {
      uploadCanvasRef.current.showSetting(tab);
      setDrawTab(tab);
    }
  };

  const [forUsedColor, setForUsedColor] = useState({
    ff0001: [],
    ff0002: [],
    ff0003: [],
    ff0004: [],
    ff0005: [],
    ff0006: [],
    ff0007: [],
    ff0008: [],
    ff0009: [],
    ff000a: [],
  });

  const onScreenShot = async () => {
    setLoading(true);
    var node = document.querySelector("#screenshot");
    var totalCountText = document.querySelector("#panel-total-count-text");
    var imageDrawToolBar = document.querySelector("#imageDrawToolBar");
    var panelAddColorBtn = document.querySelector("#panel-add-color-btn");
    var waterMark = document.querySelector("#water-mark");
    //var panelColorRemove = document.querySelector(".panel-color-remove");

    totalCountText.style.display = "none";
    imageDrawToolBar.style.display = "none";
    panelAddColorBtn.style.display = "none";
    waterMark.style.visibility = "visible";
    setIsZooming(false);
    setTimeout(() => {
      toPng(node, { quality: 1 })
        .then((dataUrl) => {
          const randrom = Math.floor(100000 + Math.random() * 900000);
          const link = document.createElement("a");
          link.download = `emirates paints.png`;
          link.href = dataUrl;
          link.click();
          setLoading(false);

          //alink.style.display = "block";
          //sideButton.style.display = "block";
          // saveProject.style.display = "block";
          // window.location.href = "https://emiratespaints.com/find-store/";
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }, 2000);
  };

  return (
    <div className="panel-four-drow-secion">
      <div id="imageDrawToolBar">
        {isMobile ? (
          <ImageDrawToolBarMobile
            onChangeImage={onChangeImage}
            onUndo={onUndo}
            onReset={onReset}
            onRedo={onRedo}
            onSave={onSave}
          />
        ) : (
          <ImageDrawToolBar
            onChangeImage={onChangeImage}
            onUndo={onUndo}
            onReset={onReset}
            onRedo={onRedo}
            onSave={onSave}
          />
        )}
      </div>

      {/* Canvas ================================================= */}
      <div className="uploaded_photo no_bg" style={{ position: "relative" }}>
        {uploadData.length > 0 ? (
          <>
            <UploadCanvas
              editImageTab={editImageTab}
              ref={uploadCanvasRef}
              width={
                window.innerWidth > uploadData[0].width
                  ? uploadData[0].width
                  : window.innerWidth * 0.8
                //uploadData[0].width
              }
              height={
                window.innerWidth > uploadData[0].width
                  ? uploadData[0].height
                  : ((window.innerWidth * 0.8) / uploadData[0].width) *
                    uploadData[0].height
                // uploadData[0].height
              }
              cnt={image.cnt}
              color={selectedColor.color_code}
              // isMobile={
              //   window.innerWidth > image.width ? false : true
              // }
              isMobile={window.innerWidth > uploadData[0].width ? false : true}
              selectedColorProps={colors}
              //  {...props}
              setResultData={(value) => {}}
              spinner={false}
              selectImageProps={image.path}
              selectMaskProps={image.cnt}
              selectedColor={selectedColor.color_code}
              selectSizeProps={{
                width: uploadData[0].width,
                height: uploadData[0].height,
              }}
              uploadData={uploadData}
              forUsedColor={forUsedColor}
              setForUsedColor={setForUsedColor}
              tab={drawTab}
            />
          </>
        ) : (
          <>
            <Canvas
              src={process.env.PUBLIC_URL + image.path}
              ref={canvasRef}
              tabProps={index}
              width={
                window.innerWidth > image.width
                  ? image.width
                  : window.innerWidth * 0.8
              }
              height={
                window.innerWidth > image.width
                  ? image.height
                  : ((window.innerWidth * 0.8) / image.width) * image.height
              }
              cnt={image.cnt}
              color={selectedColor.color_code}
              isMobile={window.innerWidth > image.width ? false : true}
              selectedColorProps={colors}
              //  {...props}
              setResultData={(value) => {}}
              spinner={false}
              selectImageProps={image.path}
              selectMaskProps={image.cnt}
              selectedColor={selectedColor.color_code}
              selectSizeProps={{
                width: image.width,
                height: image.height,
              }}
              uploadData={[]}
              forUsedColor={forUsedColor}
              setForUsedColor={setForUsedColor}
            />
          </>
        )}
      </div>
      {uploadData.length > 0 && (
        <>
          {!loading && (
            <div className="panel-four-edit-section">
              <div
                className="panel-four-edit-btn"
                onClick={() => {
                  editImageTab("Edit");
                }}
              >
                <span className="panel-four-edit-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                </span>
                <p>Edit</p>
              </div>

              <div
                className="panel-four-edit-btn"
                onClick={() => {
                  editImageTab("Erase");
                }}
              >
                <span className="panel-four-edit-icon mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-eraser-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
                  </svg>
                </span>
                <p>Eraser</p>
              </div>

              <div
                className="panel-four-edit-btn"
                onClick={() => {
                  editImageTab("Paint");
                }}
              >
                <span className="panel-four-edit-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-brush"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.118 8.118 0 0 1-3.078.132 3.659 3.659 0 0 1-.562-.135 1.382 1.382 0 0 1-.466-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04zM4.705 11.912a1.23 1.23 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.39 3.39 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3.122 3.122 0 0 0 .126-.75l-.793-.792zm1.44.026c.12-.04.277-.1.458-.183a5.068 5.068 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005a.031.031 0 0 1-.007.004zm3.582-3.043.002.001h-.002z" />
                  </svg>
                </span>
                <p>Paint</p>
              </div>
            </div>
          )}
        </>
      )}
      {!isMobile && <ScreenshotLeftBottom />}
    </div>
  );
}

export default ImageDraw;
