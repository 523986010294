import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeaderTab from "../components/new_section/HeaderTab";
import TabContent from "../components/new_section/TabContent";

function Home() {
  return (
    <Fragment>
      <div>
        <Container>
          <Row>
            <Col>
              {/* headline */}
              <Row>
                <Col>
                  <div className="page_heading">
                    <div className="heading_text">Visualize your Room</div>
                  </div>
                </Col>
              </Row>
              {/** tab head */}
              <Row>
                <Col>
                  <HeaderTab />
                </Col>
              </Row>
              {/** tab content */}
              <Row>
                <Col>
                  <TabContent />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default Home;
