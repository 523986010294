const ColorCategories = [
  {
    id: 1,
    title: "Reds",
    color_code: "#DF2228",
    group: "red",
  },
  {
    id: 2,
    title: "Oranges",
    color_code: "#ec8f2c",
    group: "orange",
  },
  {
    id: 3,
    title: "Yellows",
    color_code: "#e8de35",
    group: "yellow",
  },
  {
    id: 4,
    title: "Greens",
    color_code: "#44b24d",
    group: "green",
  },
  {
    id: 5,
    title: "Blues",
    color_code: "#3555d3",
    group: "blue",
  },

  {
    id: 6,
    title: "Whites",
    color_code: "#ffffff",
    group: "white",
  },
  {
    id: 7,
    title: "Violets",
    color_code: "#bb3e77",
    group: "violet",
  },
  {
    id: 8,
    title: "Grays",
    color_code: "#a4a5a7",
    group: "gray",
  },
  {
    id: 9,
    title: "Brown",
    color_code: "#964b00",
    group: "brown",
  },
  {
    id: 10,
    title: "Darks",
    color_code: "#000000",
    group: "dark",
  },
];

export default ColorCategories;