import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";


export default function LeftSidebar(props) {
  const params = useParams();
  const tab = params.tab;
  const [activeTab, setActiveTab] = useState("account-details");
  //   alert(props.openLeftSidebar);
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (props.openLeftSidebar == true) {
      props.open(props.openLeftSidebar);
    }
  }, [props]);

  return (
    <div className="sidebar_container left_sidebar menu_left_bar ">
      <div
        className={
          "sidebar_area menu_left_bar " + (props.openLeftSidebar ? "show_bar" : "")
        }
      >
        {/* <div
          onClick={() => {
            setShowSidebar(false);
          }}
          className="button_close"
        >
          <svg
            width="19"
            height="19"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>close</title>
            <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
          </svg>
        </div> */}

        <div className="left_side">
          <div className="left_sidebar_menus">
            <ul>
              <li>
                <Link
                  
                  to={{pathname:"https://www.emiratespaints.com/product-category/red/"}} target="_blank"
                >
                  Colors
                </Link>
                <Link
                 
                  to={{pathname:"https://www.emiratespaints.com/products"}} target= "_blank"
                >
                  Products
                </Link>
                <Link
                  
                  to={{pathname:"https://www.emiratespaints.com/inspirations"}}
                  target="_blank"
                >
                  Inspiration
                </Link>
                <Link
                  onClick={() => {
                    props.open(false);
                  }}
                  className="active"
                  to="#"
                >
                  Visualizer
                </Link>
                <Link
                
                  to={{pathname: "https://www.emiratespaints.com/find-store/"}}
                  target="_blank"
                >
                  Find A Store
                </Link>
                <Link
                  
                  to={{pathname:"https://www.emiratespaints.com/book-a-painter/"}}
                  target="_blank"
                >
                  Book A Painter
                </Link>
                <Link
                 
                  to={{pathname:"https://www.emiratespaints.com/about-us"}}
                  target="_blank"
                >
                  ABOUT US
                </Link>
                <Link
                 
                  to={{pathname:"https://www.emiratespaints.com/technical-data-sheets/"}}
                  target="_blank"
                >
                  TECHNICAL DATA SHEETS
                </Link>
                <Link
                  
                  to={{pathname:"https://www.emiratespaints.com/brochures/"}}
                  target="_blank"
                >
                  BROCHURES
                </Link>
                <Link
                  
                  to={{pathname:"https://www.emiratespaints.com/privacy-policy-2/"}}
                  target="_blank"
                >
                  PRIVACY POLICY
                </Link>
                <Link
                  
                  to={{pathname:"https://www.emiratespaints.com/terms-and-conditions/"}}
                  target="_blank"
                >
                  TERMS AND CONDITIONS
                </Link>
              </li>
            </ul>
          </div>
          <br />
          <hr style={{width:'80vw'}}/>
        </div>
      </div>
    </div>
  );
}
