import React, { useEffect } from "react";
import useCategoryStore from "../../../store/category_store";
import useOrderStore from "../../../store/order_store";

function CategoryList() {
  const categoryList = useCategoryStore((state) => state.categories);
  const current = useCategoryStore((state) => state.currentCategory);
  const onGetCategory = useCategoryStore((state) => state.getCategory);
  const onUploadData = useOrderStore((state) => state.onUploadData);

  useEffect(() => {
    onUploadData([]);
  }, []);

  return (
    <div className="panel-two-category-list">
      {categoryList.map((i) => (
        <div
          key={i.id}
          className={
            i.id === current.id
              ? "panel-two-category-active"
              : "panel-two-category"
          }
          onClick={() => {
            onGetCategory(i);
          }}
        >
          <div className="panel-two-category-text">{i.title}</div>
        </div>
      ))}
    </div>
  );
}

export default CategoryList;
