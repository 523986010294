import React from "react";
import ImageToolBtn from "./ImageToolBtn";
import { Col, Row } from "react-bootstrap";

function ImageDrawToolBar({ onChangeImage, onUndo, onReset, onRedo, onSave }) {
  return (
    <Row>
      <Col>
        <div className="panel-four-draw-toolbar">
          <ImageToolBtn title="Change Image" onPress={onChangeImage} />
          <div className="panel-four-center-section">
            <ImageToolBtn
              icon={
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.4166 6.66634C8.20829 6.66634 6.20829 7.49134 4.66663 8.83301L1.66663 5.83301V13.333H9.16663L6.14996 10.3163C7.30829 9.34967 8.78329 8.74967 10.4166 8.74967C13.3666 8.74967 15.875 10.6747 16.75 13.333L18.725 12.683C17.5666 9.19134 14.2916 6.66634 10.4166 6.66634Z"
                      fill="black"
                    />
                  </svg>
                </>
              }
              title="Undo"
              onPress={onUndo}
            />
            <ImageToolBtn
              icon={
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M8.33337 1.66699H11.6667M10 11.667V8.33366M3.33337 10.8337C3.53743 9.21364 4.32892 7.72483 5.55775 6.64961C6.78657 5.57439 8.36727 4.98753 10 5.00033C11.0784 5.00147 12.1404 5.2642 13.0949 5.76596C14.0495 6.26772 14.868 6.99355 15.4804 7.88118C16.0928 8.76881 16.4807 9.79174 16.6109 10.8622C16.741 11.9327 16.6096 13.0188 16.2278 14.0273C15.846 15.0358 15.2253 15.9367 14.4188 16.6526C13.6123 17.3685 12.6443 17.8781 11.5976 18.1377C10.5509 18.3972 9.45691 18.399 8.4094 18.1429C7.36189 17.8867 6.39215 17.3803 5.58337 16.667L3.33337 14.667"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.50004 14.167H3.33337V18.3337"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </>
              }
              title="Reset"
              onPress={onReset}
            />
            <ImageToolBtn
              icon={
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15.3333 8.83301C13.7917 7.49134 11.7917 6.66634 9.58333 6.66634C5.70832 6.66634 2.43333 9.19134 1.28333 12.683L3.24999 13.333C3.6889 11.9997 4.53737 10.8388 5.67455 10.0159C6.81173 9.19292 8.1796 8.74981 9.58333 8.74967C11.2083 8.74967 12.6917 9.34967 13.85 10.3163L10.8333 13.333H18.3333V5.83301L15.3333 8.83301Z"
                      fill="black"
                    />
                  </svg>
                </>
              }
              title="Redo"
              onPress={onRedo}
            />
          </div>
          <ImageToolBtn title="Save Project" onPress={onSave} />
        </div>
      </Col>
    </Row>
  );
}

export default ImageDrawToolBar;
