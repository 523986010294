import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });

  return (
    <>
      <footer>
        <section className="footer">
          <div className="container">
            <div className="footer_links">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <ul>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/"
                        target="_parent"
                      >
                        <img
                          className="footer_logo"
                          src={require("../assets/images/white-logo.png")}
                        />
                      </Link>
                    </li>
                    {/* <br />
                    <li className="casino_text">
                      Explore our complete range of paints
                      and colors and start painting today.
                    </li>
                    <br /> */}
                    <br />
                    <li className="social_links">
                      <Link
                        to="https://www.instagram.com/emiratespaints.mena"
                        target="_blank"
                      >
                        <div className="icon_area">
                          <span class="elementor-grid-item" data-eng-ff="Jost">
                            <i class="fab fa-instagram"></i>
                          </span>
                          {/* <i className="fab fa-instagram"></i> */}
                        </div>
                      </Link>
                      <Link
                        to="https://www.facebook.com/emiratespaints.mena"
                        target="_blank"
                      >
                        <div className="icon_area">
                          <i className="fab fa-facebook"></i>
                        </div>
                      </Link>
                      <Link
                        to="https://www.linkedin.com/company/emirates-paints/"
                        target="_blank"
                      >
                        <div className="icon_area">
                          {/* <i className="fab fa-linkedin-in"></i> */}
                          <span class="elementor-grid-item" data-eng-ff="Jost">
                            <i class="fab fa-linkedin"></i>
                          </span>
                        </div>
                      </Link>

                      {/* ===============================working*/}

                      <Link
                        to="https://www.tiktok.com/@emiratespaints"
                        target="_blank"
                      >
                        <div className="icon_area">
                          {/* <i className="fab fa-linkedin-in"></i> */}
                          {/* <span class="elementor-grid-item" data-eng-ff="Jost">
                            <i class="fab fa-tiktok"></i>
                          </span> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16"
                            width="14"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="#ffffff"
                              d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"
                            />
                          </svg>
                        </div>
                      </Link>
                      <Link
                        to="https://www.snapchat.com/add/emiratespaints"
                        target="_blank"
                      >
                        <div className="icon_area">
                          {/* <i className="fab fa-linkedin-in"></i> */}
                          <span class="elementor-grid-item" data-eng-ff="Jost">
                            {/* <i class="fab fa-tiktok"></i> */}
                            <i class="fab fa-snapchat-ghost"></i>
                          </span>
                        </div>
                      </Link>
                      <Link
                        to="https://www.pinterest.com/emiratespaints/"
                        target="_blank"
                      >
                        <div className="icon_area">
                          {/* <i className="fab fa-linkedin-in"></i> */}
                          <span class="elementor-grid-item" data-eng-ff="Jost">
                            {/* <i class="fab fa-tiktok"></i> */}
                            <i class="fab fa-pinterest-p"></i>
                          </span>
                        </div>
                      </Link>
                    </li>
                    <br />
                    {/* <li className="iso">
                      <b>
                        ISO <span>9001:2015</span>
                        <br />
                        ISO <span>45001:2018</span>
                        <br />
                        ISO <span>14001:2015</span>
                        <br />
                        HACCP
                        <br />
                      </b>
                    </li> */}
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-2 isMobileRemove">
                  <ul>
                    <li className="list_heading">Explore</li>
                    <li>
                      <span className="red_dash"></span>{" "}
                      <span className="red_dash white"></span>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/about-us"
                        target="_parent"
                      >
                        {" "}
                        {">"} About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/contact/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/terms-and-conditions/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Terms & conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/privacy-policy/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/livechat"
                        target="_parent"
                      >
                        {" "}
                        {">"} Live Chat
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-12 col-md-6 col-lg-2 isMobileRemove">
                  <ul>
                    <li className="list_heading">Browse </li>
                    <li>
                      <span className="red_dash"></span>{" "}
                      <span className="red_dash white"></span>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/product-category/red/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Find a Color
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/products/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Choose a Product
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/brochures/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Brochures
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/technical-data-sheets/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Technical Data Sheets
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/book-a-painter/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Book a Painter
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.emiratespaints.com/find-store/"
                        target="_parent"
                      >
                        {" "}
                        {">"} Find a Store
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <ul>
                    <li className="list_heading">Contact</li>
                    <li>
                      <span className="red_dash"></span>{" "}
                      <span className="red_dash white"></span>
                    </li>

                    <li>
                      <div className="contact_group">
                        <div className="icon_area">
                          <i className="fal fa-phone"></i>
                        </div>
                        <div className="text_double">
                          <div className="option_name">Call Us</div>
                          <div className="option_name value">
                            <Link to="tel:+971 42565474" target="_parent">
                              {" "}
                              +971 4 256 5474
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="contact_group">
                        <div className="icon_area">
                          <i className="fal fa-envelope-open-text"></i>
                        </div>
                        <div className="text_double">
                          <div className="option_name">Send E-mail</div>
                          <div className="option_name value">
                            <Link
                              to="mailto:info@emiratespaints.com"
                              target="_parent"
                            >
                              {" "}
                              info@emiratespaint.com
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="contact_group no_border">
                        <div className="icon_area">
                          <i className="fal fa-location"></i>
                        </div>
                        <div className="text_double">
                          <div className="option_name">
                            P.O Box 1, 14a St, Umm Ramool
                          </div>
                          <div className="option_name value">
                            Dubai, United Arab Emirates
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="copyright_section">
              <div className="copyright_text">
                © 2024 Emirates Paints. All Rights Reserved.
              </div>
            </div>
          </div>

          <div className="scrolling_up">
            <div className="whatsapp" style={{ visibility: "hidden" }}>
              <i className="fab fa-whatsapp"></i>
              <div className="status"></div>
            </div>
            <br />
            <br />
            <br />
            <div
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Optional: for smooth scrolling
                });
              }}
            >
              <div className={scroll ? "scroll_top on" : "scroll_top"}>
                <i className="fas fa-arrow-up"></i>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
