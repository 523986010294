import React, { useState, useEffect } from "react";
import Header from "../elements/Header";
import Footer from "../elements/Footer";
import ScrollTo from "../elements/ScrollTo";
import Feedback from "../components/Feedback";

const FullLayout = ({ children, ...rest }) => {
  const [spinner, setSpinner] = useState(false);
  const [tabProps, setTabProps] = useState(1);
  const [selectedColorProps, setSelectedColorProps] = useState([]);
  const [uploadImageProps, setUploadImageProps] = useState();
  const [selectImageProps, setSelectImageProps] = useState();
  const [selectMaskProps, setSelectMaskProps] = useState({ width: "", height: "" });
  const [selectSizeProps, setSelectSizeProps] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [uploadData, setUploadData] = useState([]);
  const [resultData, setResultData] = useState();
  const [forUsedColor, setForUsedColor] = useState({'ff0001':[],'ff0002':[],'ff0003':[],'ff0004':[],'ff0005':[],'ff0006':[],'ff0007':[],'ff0008':[],'ff0009':[],'ff000a':[]})

  const passableParams = {
    setTabProps,
    tabProps,
    setSpinner,
    spinner,
    setSelectedColorProps,
    selectedColorProps,
    setUploadImageProps,
    uploadImageProps,
    setSelectImageProps,
    selectImageProps,
    setSelectMaskProps,
    selectMaskProps,
    setSelectedColor,
    selectedColor,
    setSelectSizeProps,
    selectSizeProps,
    setUploadData,
    uploadData,
    setResultData,
    resultData,
    forUsedColor,
    setForUsedColor
  };
  const childrenWithProps = React.Children.map(children, (child) => {

    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  return (
    <>
      <div className="home">
        <Header />
        {/* <Feedback /> */}
        <main>{childrenWithProps}</main>
        <Footer />
      </div>
      <ScrollTo />
    </>
  );
};

export default FullLayout;
