import { create } from "zustand";
import ColorCategoryList from "./dummy_data/category_color_list";
import ColorList from "../colors.json";
import { devtools } from "zustand/middleware";

const searchColorStore = (set, get) => ({
  categories: ColorCategoryList,
  colors: ColorList,
  searchCategory: ColorCategoryList[0],
  searchColors: [],
  isSearch: false,

  setSearchColor: (colors, category, isSearch) => {
    return set((state) => ({
      searchColors: colors,
      searchCategory: category,
      isSearch: isSearch
    }));
  
  },
  

});

export const useSearchColorStore = create(devtools(searchColorStore));

export default useSearchColorStore;
