import React from "react";
import useCategoryStore from "../../../store/category_store";
import useOrderStore from "../../../store/order_store";
import useTabStore from "../../../store/tab_store";

function CategoryDetails() {
  const subCategories = useCategoryStore((state) => state.subCategories);
  const setCategory = useOrderStore((state) => state.setCategory);
  const getSubCategory = useCategoryStore((state) => state.getSubCategory);
  const currentCategory = useCategoryStore((state) => state.currentCategory);
  const currentSubCategory = useCategoryStore(
    (state) => state.currentSubCategory
  );
  const setImage = useOrderStore((state) => state.setImage);
  const onNextTab = useTabStore((state) => state.chnageIndex);

  const handler = (value) => {
    console.log("------------images", value);
    getSubCategory(value);
    setCategory(currentCategory);
    setImage(value);
    console.log("Scroll position", window.screenY);
    onNextTab(2);
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <div className="panel-two-subcatgory">
      <div className="panel-two-grid-list">
        {subCategories.map((i) => (
          <>
            <div
              key={i.id}
              className={
                currentSubCategory.id === i.id
                  ? "panel-item-grid-item-active"
                  : "panel-item-grid-item"
              }
              onClick={() => {
                handler(i);
              }}
            >
              <img
                className="panel-item-grid-item-img"
                src={process.env.PUBLIC_URL + i.path}
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default CategoryDetails;
