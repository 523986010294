import React from "react";
import useCategoryStore from "../../../../store/category_store";
import useOrderStore from "../../../../store/order_store";
import useTabStore from "../../../../store/tab_store";

function PanelTwoToolBarMobile(props) {
  const isUpload = useTabStore((state) => state.isUpload);
  const index = useTabStore((state) => state.index);
  const onUploadSection = useTabStore((state) => state.onUploadSection);
  const subCategories = useCategoryStore((state) => state.subCategories);
  const setCategory = useOrderStore((state) => state.setCategory);
  const getSubCategory = useCategoryStore((state) => state.getSubCategory);
  const currentCategory = useCategoryStore((state) => state.currentCategory);
  const currentSubCategory = useCategoryStore(
    (state) => state.currentSubCategory
  );
  const setImage = useOrderStore((state) => state.setImage);
  const onNextTab = useTabStore((state) => state.chnageIndex);

  const onNavigatePage = () => {
    const value =
      currentSubCategory.id === undefined
        ? subCategories[0]
        : currentSubCategory;
    getSubCategory(value);
    setCategory(currentCategory);
    setImage(value);
    if (props.onPreviewCanvas && index === 1) {
      props.onPreviewCanvas();
    }

    onNextTab(2);
  };

  const onUploadImage = () => {
    onUploadSection(!isUpload);
  };

  return (
    <div className="panel-two-toolbar">
      <div className="panel-two-upload-text-div">
        {/** title */}
        <p className="panel-two-title">Choose an image to visualize</p>
      </div>

      <div className="panel-two-toolbar-btn-section">
        {/** upload btn */}
        <div className="panel-two-upload" onClick={onUploadImage}>
          <div className="panel-two-upload-text">
            {isUpload ? "Library" : "Upload your own image"}
          </div>
          <img
            className="panel-two-upload-img"
            src={
              require("../../../../assets/images/octicon_upload.svg").default
            }
          ></img>
        </div>

        {/** upload btn end */}

        {/** pick color btn */}
        <div className="panel-two-color-btn" onClick={onNavigatePage}>
          <div className="panel-two-color-btn-text">Pick a Color</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M8 15L13 10L8 5"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>{" "}
        </div>
        {/** pick color btn end */}
      </div>
    </div>
  );
}

export default PanelTwoToolBarMobile;
