import { create } from "zustand";
import ColorCategoryList from "./dummy_data/category_color_list";
import ColorList from "../colors.json";
import { devtools } from "zustand/middleware";

const colorStore = (set, get) => ({
  categoriesColor: ColorCategoryList,
  subColorCategories: ColorList,
  currentCategory: ColorCategoryList[0],
  currentColors: [],

  getColorCategory: (category) => {
    const index = ColorCategoryList.findIndex((e) => e.id === category.id);

    return set((state) => ({
      currentCategory: ColorCategoryList[index],
      subColorCategories: ColorList.filter((i) => i.group === category.group),
    }));
  },

  addColor: (value) => {
    return set((state) => ({
      currentColors: [...state.currentColors, value],
    }));
  },
  removeColor: (value) => { 
    return set((state) => ({
      currentColors: state.currentColors.filter((i) => i.id !== value.id),
    }));
  }

  

});

export const useColorStore = create(devtools(colorStore));

export default useColorStore;
