import React from "react";
import useTabStore from "../../store/tab_store";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";


const PlaneList = [<StepOne />, <StepTwo />, <StepThree />];

function TabContent() {
  const tabIndex = useTabStore((state) => state.index);
    return <div> {PlaneList[tabIndex - 1]}</div>;
}

export default TabContent;
