import React from "react";
import { Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

function DropImageUpload({ onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ accept, onDrop });
  const files = acceptedFiles.map((file) => (
    <div key={file.path}>
      <div className="panel-two-upload-item">
        <img
          src={URL.createObjectURL(file)}
          className="panel-two-upload-img"
          width="100px"
          height="80px"
        />
        <div className="panel-two-upload-text-section ">
          <p className="panel-two-upload-text">{file.name}</p>
          <p className="panel-two-upload-text-size">
            {(file.size / 1024 / 1024).toFixed(1)} mb
          </p>
        </div>
      </div>
    </div>
  ));
  return (
    <div
      {...getRootProps({
        className: "dropzone panel-two-darg-file-section",
      })}
    >
      <input className="input-zone" {...getInputProps()} />
      <div className="text-center panel-two-darg-drop-box">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <p className="dropzone-content">
            Drag’n’drop some files here, or click to select files
          </p>
        )}
        <button type="button" onClick={open} className="btn">
          Click to select files
        </button>
      </div>
      <aside>{files}</aside>
    </div>
  );
}

export default DropImageUpload;
