import { create } from "zustand";
import CategoryList from "./dummy_data/category_list";
import ImageList from "../images.json";
import { devtools } from "zustand/middleware";

const categoryStore = (set) => ({
  categories: CategoryList,
  subCategories: ImageList,
  currentCategory: CategoryList[0],
  currentSubCategory: {},

  getCategory: (category) => {
    const index = CategoryList.findIndex((e) => e.id === category.id);

    return set((state) => ({
      currentCategory: CategoryList[index],
      subCategories:
        category.id === 1
          ? ImageList
          : ImageList.filter((i) => i.group === category.group),
    }));
  },

  getSubCategory: (value) => {
    return set((state) => ({
      currentSubCategory: value,
    }));
  },
});

export const useCategoryStore = create(devtools(categoryStore));

export default useCategoryStore;
