import { create } from "zustand";

import { devtools, persist } from "zustand/middleware";

const tabStore = (set) => ({
  index: 1,
  isMobile: window.innerWidth <= 550,
  isOurImage: false,
  isUpload: false,
  chnageIndex: (value) => set((state) => ({ index: value })),
  onOurImage: (value) => set((state) => ({ isOurImage: value })),
  onUpload: (value) => set((state) => ({ isUpload: value, isOurImage: value })),
  onUploadSection: (value) => set((state) => ({ isUpload: value,})),
});

const useTabStore = create(tabStore);

export default useTabStore;
