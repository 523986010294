import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import maskColorList from "../../mask_colors.json";
import { multiply } from "color-blend";
import useZoomStore from "../../store/zooming_store";

const Canvas = forwardRef((props, ref) => {
  const canvasRef = useRef(null);
  const zoomRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const regionRef = useRef(null);
  const mainOverCanvasRef = useRef(null);

  var canvas, zoom, overlay, region, mainOver;
  var cxt_img, cxt_zoom, cxt_overlay, cxt_region, cxt_mainOver;

  const [zoomTop, setZoomTop] = useState("0");
  const [zoomLeft, setZoomLeft] = useState("0");
  const [zoomVisible, setZoomVisible] = useState(false);
  const [zoomColor, setZoomColor] = useState("red");
  const [imgWidth, setImgWidth] = useState(props.width);
  const [imgHeight, setImgHeight] = useState(props.height);
  const [clickPoint, setClickPoint] = useState([-1, -1, -1, -1]);
  const [gOverData, setGOverData] = useState(null);
  const [gOrgData, setGOrgData] = useState(null);
  const [gEmptyData, setGEmptyData] = useState(null);
  const [currentState, setCurrentState] = useState(0);
  const [workTemp, setWorkTemp] = useState([]);
  const isZooming = useZoomStore((state) => state.zooming);
  // const [forUsedColor, setForUsedColor] = useState({'fdfd16':[],'ffff17':[],'ffff18':[],'ffff19':[],'ffff1a':[],'ffff1b':[],'ffff1c':[],'ffff1d':[],'ffff16':[],'ffff20':[]})
  const zoomWidth = 100;
  var mask_img = [];
  var mask_load_cnt = 0;
  var overData, orgData;

  // console.log(ref);
  useImperativeHandle(ref, () => ({
    reset: () => {
      setWorkTemp([gOrgData]);
      setCurrentState(0);
      var c = canvasRef.current.getContext("2d");
      c.putImageData(gOrgData, 0, 0);
      props.setResultData(gOrgData);
    },
    redo() {
      if (currentState < workTemp.length - 1) {
        setCurrentState(currentState + 1);
        var c = canvasRef.current.getContext("2d");
        c.putImageData(workTemp[currentState + 1], 0, 0);
        props.setResultData(workTemp[currentState + 1]);
      }
    },
    undo() {
      if (currentState > 0) {
        setCurrentState(currentState - 1);
        var c = canvasRef.current.getContext("2d");
        c.putImageData(workTemp[currentState - 1], 0, 0);
        props.setResultData(workTemp[currentState - 1]);
      }
    },
  }));

  useEffect(() => {
    console.log(props);
    mask_img = [];
    mask_load_cnt = 0;

    canvas = canvasRef.current;
    zoom = zoomRef.current;
    overlay = overlayCanvasRef.current;
    region = regionRef.current;
    mainOver = mainOverCanvasRef.current;

    cxt_img = canvas.getContext("2d");
    cxt_zoom = zoom.getContext("2d");
    cxt_overlay = overlay.getContext("2d");
    cxt_region = region.getContext("2d");
    cxt_mainOver = mainOver.getContext("2d");

    var imageObj1 = new Image();
    imageObj1.src = props.src;

    imageObj1.onload = function () {
      setImgWidth(props.width);
      setImgHeight(props.height);
      // console.log(imageObj1.width);
      cxt_img.canvas.width = props.width;
      cxt_img.canvas.height = props.height;

      cxt_overlay.canvas.width = props.width;
      cxt_overlay.canvas.height = props.height;

      cxt_mainOver.canvas.width = props.width;
      cxt_mainOver.canvas.height = props.height;

      cxt_img.drawImage(imageObj1, 0, 0, props.width, props.height);
      orgData = cxt_img.getImageData(0, 0, props.width, props.height);
      setGOrgData(orgData);

      ////////////for redo undo///////////////
      setWorkTemp([orgData]);
      // let tmp = orgData;
      // tmp.data.map(data => data = 0);
      setGEmptyData(cxt_mainOver.getImageData(0, 0, props.width, props.height));
      props.setResultData(orgData);
    };

    // imageObj1.onload = function () {
    //     setImgWidth(imageObj1.width);
    //     setImgHeight(imageObj1.height);
    //     // console.log(imageObj1.width);
    //     cxt_img.canvas.width = imageObj1.width;
    //     cxt_img.canvas.height = imageObj1.height;

    //     cxt_overlay.canvas.width = imageObj1.width;
    //     cxt_overlay.canvas.height = imageObj1.height;

    //     cxt_mainOver.canvas.width = imageObj1.width;
    //     cxt_mainOver.canvas.height = imageObj1.height;

    //     cxt_img.drawImage(imageObj1, 0, 0);
    //     orgData = cxt_img.getImageData(0, 0, imgWidth, imgHeight);
    //     setGOrgData(orgData);

    //     ////////////for redo undo///////////////
    //     setWorkTemp([orgData]);
    //     // let tmp = orgData;
    //     // tmp.data.map(data => data = 0);
    //     setGEmptyData(cxt_mainOver.getImageData(0, 0, imgWidth, imgHeight));
    // }

    for (var index = 1; index <= props.cnt; index++) {
      const image = new Image();
      image.src = props.src.replace(".jpg", "_" + index + ".png");
      image.onload = () => {
        mask_load_cnt += 1;
        if (mask_img.length == props.cnt && props.cnt == mask_load_cnt) {
          // have all loaded????
          {
            mask_img.map((data, j) => {
              if (data.width * data.height != 0) {
                // console.log(data);
                cxt_region.canvas.width = props.width;
                cxt_region.canvas.height = props.height;

                cxt_region.drawImage(data, 0, 0, props.width, props.height);
                if (j == 0) {
                  cxt_overlay.drawImage(data, 0, 0, props.width, props.height);

                  overData = cxt_overlay.getImageData(
                    0,
                    0,
                    props.width,
                    props.height
                  );
                  for (let i = 0; i < overData.data.length; i++) {
                    overData.data[i] = 0;
                  }
                }

                const imgData = cxt_region.getImageData(
                  0,
                  0,
                  props.width,
                  props.height
                );
                // if (j == 0) overData = imgData;
                // console.log(imgData.data);
                for (let i = 0; i < imgData.data.length; i += 4) {
                  if (
                    imgData.data[i] <= 1 &&
                    imgData.data[i + 1] <= 1 &&
                    imgData.data[i + 2] <= 1 &&
                    imgData.data[i + 3] >= 240
                  ) {
                    // overData.data[i] = 255;
                    overData.data[i] = maskColorList[j].r;
                    overData.data[i + 1] = maskColorList[j].g;
                    overData.data[i + 2] = maskColorList[j].b;
                    overData.data[i + 3] = 255;
                  }
                }
                // console.log(overData.data);
              } else {
              }
            });
            // console.log(overData.data);
            cxt_overlay.putImageData(overData, 0, 0);
            setGOverData(overData);
            // cxt_overlay.save();
            // cxt_overlay.drawImage(overData, 0, 0)
            // allLoaded(); // call function to start rendering
          }
        }
      };
      mask_img.push(image); // add loading image to images array

      props.selectedColorProps.map((data) => (data.isUsed = false));
    }

    // for (var index = 1; index <= props.cnt; index++) {

    //     const image = new Image();
    //     image.src = props.src.replace(".jpg", "_" + index + ".png");
    //     image.onload = () => {
    //         mask_load_cnt += 1;
    //         if (mask_img.length == props.cnt && props.cnt == mask_load_cnt) { // have all loaded????
    //             {
    //                 mask_img.map((data, j) => {
    //                     if (data.width * data.height != 0) {
    //                         console.log(data);
    //                         cxt_region.canvas.width = data.width;
    //                         cxt_region.canvas.height = data.height;

    //                         cxt_region.drawImage(data, 0, 0);
    //                         if (j == 0) {
    //                             cxt_overlay.drawImage(data, 0, 0);

    //                             overData = cxt_overlay.getImageData(0, 0, data.width, data.height);
    //                             for (let i = 0; i < overData.data.length; i++) {
    //                                 overData.data[i] = 0;
    //                             }
    //                         }

    //                         const imgData = cxt_region.getImageData(0, 0, data.width, data.height)
    //                         // if (j == 0) overData = imgData;
    //                         // console.log(imgData.data);
    //                         for (let i = 0; i < imgData.data.length; i += 4) {
    //                             if (imgData.data[i] <= 1 && imgData.data[i + 1] <= 1 && imgData.data[i + 2] <= 1 && imgData.data[i + 3] >= 240) {
    //                                 // overData.data[i] = 255;
    //                                 overData.data[i] = maskColorList[j].r;
    //                                 overData.data[i + 1] = maskColorList[j].g;
    //                                 overData.data[i + 2] = maskColorList[j].b;
    //                                 overData.data[i + 3] = 255;
    //                             }

    //                         }
    //                         // console.log(overData.data);
    //                     } else {

    //                     }

    //                 })
    //                 // console.log(overData.data);
    //                 cxt_overlay.putImageData(overData, 0, 0);
    //                 setGOverData(overData);
    //                 // cxt_overlay.save();
    //                 // cxt_overlay.drawImage(overData, 0, 0)
    //                 // allLoaded(); // call function to start rendering
    //             }
    //         }
    //     }
    //     mask_img.push(image); // add loading image to images array

    // }
  }, []);

  function handleMouseMove(e) {
    // const { x, y } = e.nativeEvent;
    if (e) {
      ///////////////zoom color from origin layer////////////////////////////////
      if (isZooming) {
        setZoomVisible(!props.isMobile);
      }
      var position = findPos(canvasRef.current);
      var x = e.pageX - position.x;
      var y = e.pageY - position.y;
      const { screenX, screenY } = e;
      setZoomTop(screenY - 2 * zoomWidth);
      setZoomLeft(screenX - zoomWidth);
      let c = canvasRef.current.getContext("2d");
      // let c = e.currentTarget.getContext('2d');
      let p = c?.getImageData(x, y, 1, 1).data;
      setZoomColor("#" + rgbToHex(p[0], p[1], p[2]));

      //////////////get color from focus layer///////////////////////////

      position = findPos(overlayCanvasRef.current);
      c = overlayCanvasRef.current.getContext("2d");
      let p1 = c?.getImageData(x, y, 1, 1).data;

      //////////////display selected segment of wall/////////////////
      //////////////////if not the white color////////////
      var tmp_overData = new ImageData(
        new Uint8ClampedArray(gEmptyData.data),
        gEmptyData.width,
        gEmptyData.height
      );
      // console.log(tmp_overData);
      // var tmp_overData = lodashClonedeep(gOverData);
      var c1 = mainOverCanvasRef.current.getContext("2d");

      // console.log(p1);
      // if (clickPoint[0] == p1[0] && clickPoint[1] == p1[1] && clickPoint[2] == p1[2] && clickPoint[3] == p1[3]) {
      //     // c1.putImageData(gOverData, 0, 0)
      //     // console.log(gOverData.data);
      // } else {
      setClickPoint(p1);

      // var tmp_overData = new ImageData(gOverData.data, gOverData.width, gOverData.height);
      // tmp_overData = Object.assign({}, gOverData);
      // var tmp_overData = { ...gOverData }
      // console.log(p1);
      // console.log(gOverData.data);
      // console.log(tmp_overData.data);
      // var tmp_overData = c1.getImageData(0, 0, imgWidth, imgHeight);
      // if (p1[0] + p1[1] + p1[2] + p1[3] != 0) {

      if (!props.isMobile) {
        console.log("----------------------gOverData", gOverData);

        for (let i = 0; i < tmp_overData.data.length; i += 24) {
          // tmp_overData.data[i] = gOrgData.data[i];

          if (
            gOverData.data[i] == p1[0] &&
            gOverData.data[i + 1] == p1[1] &&
            gOverData.data[i + 2] ==
              p1[2] /* && gOverData.data[i + 3] == p1[3]*/
          ) {
            for (let j = -4; j < 5; j += 4) {
              tmp_overData.data[i + j] = 0;
              tmp_overData.data[i + j + 1] = 0;
              tmp_overData.data[i + j + 2] = 255;
              if (p1[0] + p1[1] + p1[2] + p1[3] != 0)
                //+ p1[3]
                tmp_overData.data[i + j + 3] = 255;
              else tmp_overData.data[i + j + 3] = 0;
            }
            // tmp_overData.data[i] = 0;
            // tmp_overData.data[i + 1] = 0;
            // tmp_overData.data[i + 2] = 255;
            // if (p1[0] + p1[1] + p1[2] + p1[3] != 0)
            //     tmp_overData.data[i + 3] = 255;
            // else
            //     tmp_overData.data[i + 3] = 0;
          }
        }
        // console.log(gOverData.data);
        // console.log(tmp_overData.data);
        if (!props.isMobile && isZooming) {
          c1.putImageData(tmp_overData, 0, 0);
        }
      }
    }
  }

  function rgbToHex(r, g, b) {
    if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
    return ((r << 16) | (g << 8) | b).toString(16);
  }

  function hexToRgb(hex) {
    hex = hex.replace("#", "");
    var aRgbHex = hex.match(/.{1,2}/g);
    var aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16),
    ];
    return aRgb;
  }

  function findPos(obj) {
    var current_left = 0,
      current_top = 0;
    if (obj.offsetParent) {
      do {
        current_left += obj.offsetLeft;
        current_top += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return { x: current_left, y: current_top };
    }
    return undefined;
  }

  function handleMouseLeave() {
    setZoomVisible(false);

    var c1 = mainOverCanvasRef.current.getContext("2d");
    c1.putImageData(gEmptyData, 0, 0);
  }

  function handleMouseDown(e) {
    if (e) {
      var tmp = rgbToHex(clickPoint[0], clickPoint[1], clickPoint[2]);
      console.log(tmp);
      var color = hexToRgb(
        props.color ? props.color : props.selectedColorProps[0].color_code
      );

      if (props.color) {
        props.selectedColorProps.map((data) => {
          if (data.color_code == props.color) {
            data.isUsed = true;
          }
        });
      } else {
        props.selectedColorProps[0].isUsed = true;
      }

      if (tmp != 0) {
        props.forUsedColor[`${tmp}`] = color;
      }
      props.setForUsedColor(props.forUsedColor);

      console.log(color);
      console.log(clickPoint);
      var c = canvasRef.current.getContext("2d");
      // var c1 = mainOverCanvasRef.current.getContext('2d');
      orgData = c.getImageData(0, 0, imgWidth, imgHeight);
      // overData = overlayCanvasRef.current.getContext('2d').getImageData(0, 0, imgWidth, imgHeight);
      overData = gOverData;
      for (let i = 0; i < overData.data.length; i += 4) {
        if (
          overData.data[i] +
            overData.data[i + 1] +
            overData.data[i + 2] +
            overData.data[i + 3] !=
          0
        )
          if (
            overData.data[i] == clickPoint[0] &&
            overData.data[i + 1] == clickPoint[1] &&
            overData.data[i + 2] ==
              clickPoint[2] /*&& overData.data[i + 3] == clickPoint[3]*/
          ) {
            let res = multiply(
              {
                r: gOrgData.data[parseInt(i + imgWidth * 4)],
                g: gOrgData.data[parseInt(i + imgWidth * 4 + 1)],
                b: gOrgData.data[parseInt(i + imgWidth * 4 + 2)],
                a: 255,
              },
              { r: color[0], g: color[1], b: color[2], a: 255 }
            );
            for (var j = -4; j < 5; j += 4) {
              orgData.data[i + j] = res.r; // color[0];
              orgData.data[i + j + 1] = res.g; // color[1];
              orgData.data[i + j + 2] = res.b; // color[2];
              orgData.data[i + j + 3] = 255;
            }
            orgData.data[parseInt(i + imgWidth * 4)] = res.r; // color[0];
            orgData.data[parseInt(i + imgWidth * 4 + 1)] = res.g; // color[1];
            orgData.data[parseInt(i + imgWidth * 4 + 2)] = res.b; // color[2];
            orgData.data[parseInt(i + imgWidth * 4 + 3)] = 255;
          }
      }
      // let c = canvasRef.current.getContext('2d');
      // let c = e.currentTarget.getContext('2d');
      // let p = c?.getImageData(x, y, 1, 1).data;
      c.putImageData(orgData, 0, 0);

      props.setResultData(orgData);
      setWorkTemp([...workTemp, orgData]);
      setCurrentState(currentState + 1);
      // c1.putImageData(orgData, 0, 0)
    }
  }

  return (
    <div style={{ borderRadius: "16px" }}>
      <canvas ref={canvasRef} {...props} className="paint-canvas" />
      <canvas
        ref={zoomRef}
        className="paint-canvas"
        width={zoomWidth}
        height={zoomWidth}
        style={{
          position: "fixed",
          zIndex: "20",
          top: zoomTop,
          left: zoomLeft,
          backgroundColor: zoomColor,
          borderRadius: "50%",
          visibility: zoomVisible == true ? "visible" : "hidden",
        }}
      />
      <canvas
        ref={overlayCanvasRef}
        className="paint-canvas"
        style={{
          maxWidth: "100%",
          position: "absolute",
          transform: `translateX(-${imgWidth}px)`,
          zIndex: "-10",
          visibility: "visible",
        }}
      />
      <canvas
        className="paint-canvas"
        ref={mainOverCanvasRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        // onTouchStart={handleMouseMove}
        // onTouchEnd={handleMouseLeave}
        onClick={handleMouseDown}
        style={{
          maxWidth: "100%",
          position: "absolute",
          transform: `translateX(-${imgWidth}px)`,
          zIndex: "10",
          visibility: "visible",
        }}
      />
      <canvas
        className="paint-canvas"
        ref={regionRef}
        style={{
          maxWidth: "100%",
          position: "absolute",
          transform: `translateX(-${imgWidth}px)`,
          zIndex: "-10",
          visibility: "visible",
        }}
      />
    </div>
  );
});
// style = "position:fixed; top:0; left:0; background-color:red;"
export default Canvas;
