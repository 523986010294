import React from "react";
import PanelOne from "../panel/PanelOne";
import useTabStore from "../../../store/tab_store";
import PanelTwo from "../panel/PanelTwo";

function StepOne() {
  const isOurImage = useTabStore((state) => state.isOurImage);
  return <div>{isOurImage ? <PanelTwo /> : <PanelOne />}</div>;
}

export default StepOne;
