import React from "react";
import PanelThreeSelectedColors from "./compnents/PanelThreeSelectedColors";
import ImageDraw from "./ImageDraw";
import useTabStore from "../../../store/tab_store";
import ScreenshotLeftBottom from "./compnents/ScreenshotLeftBottom";

function PanelFour(props) {
  const isMobile = useTabStore((state) => state.isMobile);
  return (
    <div className="body" id="screenshot">
      <div className="panel-four-sub-category-section">
        {!isMobile && <PanelThreeSelectedColors />}
        <ImageDraw notify={props.notify} />
        {isMobile && <PanelThreeSelectedColors />}
      </div>
      {isMobile && <ScreenshotLeftBottom />}
    </div>
  );
}

export default PanelFour;
