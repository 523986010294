import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import cv from "@techstark/opencv-js";
import DeltaE from "delta-e";
import { multiply } from "color-blend";
import useZoomStore from "../../store/zooming_store";

class ImageData1 {
  constructor(ctx, main_ctx, select_ctx, w, h, segData) {
    this.ctx = ctx;
    this.main_ctx = main_ctx;
    this.select_ctx = select_ctx;
    this.w = w;
    this.h = h;
    this.segData = segData;
    this.imd = ctx.getImageData(0, 0, w, h);
    this.main_imd = main_ctx.getImageData(0, 0, w, h);
    this.select_imd = select_ctx.getImageData(0, 0, w, h);
    this.origin_imd = select_ctx.getImageData(0, 0, w, h);
  }

  getPixel(x, y) {
    var i = 4 * (y * this.w + x);
    var d = this.imd.data;
    return [d[i], d[i + 1], d[i + 2], d[i + 3]];
  }

  getPixelMain(x, y) {
    var i = 4 * (y * this.w + x);
    var d = this.origin_imd.data;
    return [d[i], d[i + 1], d[i + 2], d[i + 3]];
  }

  //colorBurn multiply
  setPixel(x, y, color) {
    var i = 4 * (y * this.w + x);
    var d = this.imd.data;
    var m = this.main_imd.data;
    var sel = this.select_imd.data;
    var s = this.segData[this.segData.length - 1].data;
    let target = this.getPixelMain(x, y);
    let res = multiply(
      { r: target[0], g: target[1], b: target[2], a: target[3] },
      { r: color[0], g: color[1], b: color[2], a: color[3] }
    );
    [d[i], d[i + 1], d[i + 2]] = color;
    [sel[i], sel[i + 1], sel[i + 2]] = color;
    [m[i], m[i + 1], m[i + 2], m[i + 3]] = [res.r, res.g, res.b, 255];
    [s[i], s[i + 1], s[i + 2], s[i + 3]] = [res.r, res.g, res.b, 255];

    // for (let j = -1; j < 2; j ++) {
    //   var i = 4 * (y * this.w + x + j);
    //   var d = this.imd.data;
    //   var m = this.main_imd.data;
    //   [d[i], d[i + 1], d[i + 2], d[i + 3]] = color;
    //   [m[i], m[i + 1], m[i + 2], m[i + 3]] = color;
    // }
  }

  each(callback) {
    var d = this.imd.data;
    for (var i = 0; i < d.length; i += 4)
      callback([d[i], d[i + 1], d[i + 2], d[i + 3]]);
  }

  map(callback) {
    var d = this.imd.data;
    for (var i = 0; i < d.length; i += 4)
      [d[i], d[i + 1], d[i + 2], d[i + 3]] = callback([
        d[i],
        d[i + 1],
        d[i + 2],
        d[i + 3],
      ]);
  }

  deltaE(rgbA, rgbB) {
    let labA = this.rgb2lab(rgbA);
    let labB = this.rgb2lab(rgbB);
    let deltaL = labA[0] - labB[0];
    let deltaA = labA[1] - labB[1];
    let deltaB = labA[2] - labB[2];
    let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    let deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    let sc = 1.0 + 0.045 * c1;
    let sh = 1.0 + 0.015 * c1;
    let deltaLKlsl = deltaL / 1.0;
    let deltaCkcsc = deltaC / sc;
    let deltaHkhsh = deltaH / sh;
    let i =
      deltaLKlsl * deltaLKlsl +
      deltaCkcsc * deltaCkcsc +
      deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
  }

  rgb2lab(rgb) {
    let r = rgb[0] / 255,
      g = rgb[1] / 255,
      b = rgb[2] / 255,
      x,
      y,
      z;
    r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;
    // return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
    return { L: 116 * y - 16, A: 500 * (x - y), B: 200 * (y - z) };
  }

  equalColor(c1, c2, threshold = 0) {
    // console.log(this.deltaE(c1,c2));
    // return this.deltaE(c1,c2)<threshold;
    //     console.log("1+"+DeltaE.getDeltaE76(this.rgb2lab(c1), this.rgb2lab(c2)));

    // // 1994 formula
    // console.log("2+"+DeltaE.getDeltaE94(this.rgb2lab(c1), this.rgb2lab(c2)));

    // // 2000 formula
    // console.log("3+"+DeltaE.getDeltaE00(this.rgb2lab(c1), this.rgb2lab(c2)));
    return DeltaE.getDeltaE00(this.rgb2lab(c1), this.rgb2lab(c2)) <= 0.2;
    // var rmean = ( c1[0] + c2[0] ) / 2;
    // var r = c1[0] - c2[0];
    // var g = c1[1] - c1[1];
    // var b = c1[2] - c2[2];
    // return Math.sqrt((((512+rmean)*r*r)>>8) + 4*g*g + (((767-rmean)*b*b)>>8))<30;

    // return c1.every((e, i) => Math.abs(e - c2[i]) <= threshold);
  }

  floodfill([x, y], threshold, replace) {
    var targetColor = this.getPixel(x, y);
    if (this.equalColor(targetColor, replace)) return;
    var pixels = [];
    var erasePos = [];
    pixels.push([x, y]);

    var maxX = 0,
      maxY = 0,
      minX = 999999,
      minY = 999999;

    this.segData = [
      ...this.segData,
      new ImageData(
        new Uint8ClampedArray(this.segData[0].data),
        this.segData[0].width,
        this.segData[0].height
      ),
    ];
    while (pixels.length > 0) {
      var a = pixels.pop();
      if (a[0] < this.w && a[0] > 0 && a[1] < this.h && a[1] > 0) {
        var current = this.getPixel(a[0], a[1]);
        if (this.equalColor(current, targetColor, threshold)) {
          if (a[0] > maxX) {
            maxX = a[0];
            erasePos[0] = a;
          }
          if (a[1] > maxY) {
            maxY = a[1];
            erasePos[1] = a;
          }
          if (a[0] < minX) {
            minX = a[0];
            erasePos[2] = a;
          }
          if (a[1] < minY) {
            minY = a[1];
            erasePos[3] = a;
          }
          this.setPixel(a[0], a[1], replace);
          pixels.push([a[0] - 1, a[1]]);
          pixels.push([a[0] + 1, a[1]]);
          pixels.push([a[0], a[1] - 1]);
          pixels.push([a[0], a[1] + 1]);
          // pixels.push([a[0] - 1, a[1] + 1]);
          // pixels.push([a[0] + 1, a[1] + 1]);
          // pixels.push([a[0] - 1, a[1] - 1]);
          // pixels.push([a[0] + 1, a[1] - 1]);
        }
        // else {
        //   for (var x0 = -1; x0 <2; x0 ++)
        //     for (var y0 = -1; y0 <2; y0 ++)
        //     {
        //       var c = this.getPixel(a[0] + x0, a[1] + y0);
        //       if (!this.equalColor(c, targetColor,threshold)) {
        //         this.setPixel(a[0] + x0, a[1] + y0, replace);
        //       }
        //     }
        // }
      }
    }

    var eraseX = 0,
      eraseY = 0;

    for (let i = 0; i < 4; i++) {
      eraseX += erasePos[i][0] / 4;
      eraseY += erasePos[i][1] / 4;
    }
    return [eraseX, eraseY];
  }

  updateCanvas() {
    this.ctx.putImageData(this.imd, 0, 0);
    this.main_ctx.putImageData(this.main_imd, 0, 0);
    this.select_ctx.putImageData(this.select_imd, 0, 0);
    // return this.imd;
  }
}

const UploadCanvas = forwardRef((props, ref) => {
  const tmpCanvasRef = useRef(null);
  const selectCanvasRef = useRef(null);

  const canvasRef = useRef(null);
  const zoomRef = useRef(null);
  const overlayCanvasRef = useRef(null);

  var tmpCanvas, selectCanvas, canvas, zoom, overlay;
  var cxt_tmpCanvas, cxt_selectCanvas, cxt_img, cxt_zoom, cxt_overlay;

  const zoomWidth = 100;

  const [zoomTop, setZoomTop] = useState("0");
  const [zoomLeft, setZoomLeft] = useState("0");
  const [zoomVisible, setZoomVisible] = useState(false);
  const [areaVisible, setAreaVisible] = useState(false);
  const [eraseVisible, setEraseVisible] = useState(false);
  const [zoomColor, setZoomColor] = useState("red");
  const [imgHeight, setImgHeight] = useState(props.uploadData[0].height);
  const [imgWidth, setImgWidth] = useState(props.uploadData[0].width);
  const [imageData, setImageData] = useState();
  const [gEmptyData, setGEmptyData] = useState();
  const [gCVData, setGCVData] = useState();
  const [currentState, setCurrentState] = useState(0);
  const [workTemp, setWorkTemp] = useState([]);
  const [segData, setSegData] = useState([]);

  var [areaPos, setAreaPos] = useState([]);
  var [erasePos, setErasePos] = useState([]);
  const [forceLeft, setForceLeft] = useState();

  const [first, setFirst] = useState(120);
  const [second, setSecond] = useState(20);
  const isZooming = useZoomStore((state) => state.zooming);
  // console.log(ref);
  useImperativeHandle(ref, () => ({
    reset: () => {
      setWorkTemp([
        {
          main_imd: [props.uploadData[0]],
          imd: gCVData,
          area: [],
          erase: [],
          type: "Paint",
        },
      ]);
      setCurrentState(0);
      let c = canvasRef.current.getContext("2d");
      c.putImageData(props.uploadData[0], 0, 0);
      let c1 = tmpCanvasRef.current.getContext("2d");
      c1.putImageData(gCVData, 0, 0);
      setAreaPos([]);
      setErasePos([]);
      props.editImageTab("Paint");
      props.setResultData(props.uploadData[0]);
    },
    redo() {
      if (currentState < workTemp.length - 1) {
        setCurrentState(currentState + 1);
        let c = canvasRef.current.getContext("2d");
        c.putImageData(workTemp[currentState + 1].main_imd, 0, 0);
        let c1 = tmpCanvasRef.current.getContext("2d");
        c1.putImageData(workTemp[currentState + 1].imd, 0, 0);
        setAreaPos(workTemp[currentState + 1].area);
        setErasePos(workTemp[currentState + 1].erase);
        props.editImageTab(workTemp[currentState + 1].type);
        props.setResultData(workTemp[currentState + 1].main_imd);
      }
    },
    undo() {
      if (currentState > 0) {
        setCurrentState(currentState - 1);
        let c = canvasRef.current.getContext("2d");
        c.putImageData(workTemp[currentState - 1].main_imd, 0, 0);
        let c1 = tmpCanvasRef.current.getContext("2d");
        c1.putImageData(workTemp[currentState - 1].imd, 0, 0);
        setAreaPos(workTemp[currentState - 1].area);
        setErasePos(workTemp[currentState - 1].erase);
        props.editImageTab(workTemp[currentState - 1].type);
        props.setResultData(workTemp[currentState - 1].main_imd);
      }
    },
    preview() {
      areaPos.map((data, i) => {
        if (i % 2 == 1) {
          let c = tmpCanvasRef.current.getContext("2d");
          let c1 = canvasRef.current.getContext("2d");
          let c2 = selectCanvasRef.current.getContext("2d");
          c.beginPath();
          // c.lineWidth = 5;
          c.moveTo(data.x + 10, data.y + 10);
          c.lineTo(areaPos[i - 1].x + 10, areaPos[i - 1].y + 10);
          c.strokeStyle = "#ffffff";
          c.stroke();
          let img = new ImageData1(
            c,
            c1,
            c2,
            imgWidth,
            imgHeight,
            imageData.segData
          );
          setImageData(img);
        }
      });
    },
    showSetting(tag) {
      if (tag === "Paint") {
        // setZoomVisible(true);
        setAreaVisible(false);
        setEraseVisible(false);
      } else if (tag === "Edit") {
        // setZoomVisible(true);
        setAreaVisible(true);
        setEraseVisible(false);
      } else if (tag === "Erase") {
        // setZoomVisible(false);
        setAreaVisible(false);
        setEraseVisible(true);
      }
    },
  }));

  useEffect(() => {
    console.log(props.width, imgWidth);

    tmpCanvas = tmpCanvasRef.current;
    selectCanvas = selectCanvasRef.current;
    canvas = canvasRef.current;
    zoom = zoomRef.current;
    overlay = overlayCanvasRef.current;

    cxt_tmpCanvas = tmpCanvas.getContext("2d");
    cxt_selectCanvas = selectCanvas.getContext("2d");
    cxt_img = canvas.getContext("2d");
    cxt_zoom = zoom.getContext("2d");
    cxt_overlay = overlay.getContext("2d");

    cxt_tmpCanvas.canvas.width = imgWidth;
    cxt_tmpCanvas.canvas.height = imgHeight;

    cxt_selectCanvas.canvas.width = imgWidth;
    cxt_selectCanvas.canvas.height = imgHeight;

    cxt_img.canvas.width = props.width;
    cxt_img.canvas.height = props.height;

    cxt_overlay.canvas.width = props.width;
    cxt_overlay.canvas.height = props.height;

    cxt_img.putImageData(props.uploadData[0], 0, 0);
    cxt_selectCanvas.putImageData(props.uploadData[0], 0, 0);
    setGEmptyData(cxt_overlay.getImageData(0, 0, imgWidth, imgHeight));
    setForceLeft((canvasRef.current.parentElement.offsetWidth - imgWidth) / 2);
    let src = cv.imread(canvas);
    cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);
    // cv.equalizeHist(src, src);
    // let tileGridSize = new cv.Size(8, 8);
    // let clahe = new cv.CLAHE(40, tileGridSize);
    // clahe.apply(src, src);
    // cv.Laplacian(src, src, cv.CV_8U, 1, 1, 0, cv.BORDER_DEFAULT);
    cv.Canny(src, src, parseInt(first), parseInt(second), 3, false);
    cv.Laplacian(src, src, cv.CV_8U, 1, 1, 0, cv.BORDER_DEFAULT);
    cv.imshow(tmpCanvas, src);
    // segData = [cxt_overlay.getImageData(0,0,imgWidth,imgHeight)];
    const img = new ImageData1(
      cxt_tmpCanvas,
      cxt_img,
      cxt_selectCanvas,
      imgWidth,
      imgHeight,
      [
        new ImageData(
          new Uint8ClampedArray(
            cxt_overlay.getImageData(0, 0, imgWidth, imgHeight).data
          ),
          imgWidth,
          imgHeight
        ),
      ]
    );
    setImageData(img);
    setGCVData(img.imd);
    src.delete();

    setWorkTemp([
      {
        main_imd: props.uploadData[0],
        imd: img.imd,
        area: [],
        erase: [],
        type: "Paint",
      },
    ]);
    props.setResultData(props.uploadData[0]);
    props.selectedColorProps.map((data) => (data.isUsed = false));
  }, []);

  function handleMouseMove(e) {
    // const { x, y } = e.nativeEvent;
    if (e) {
      ///////////////zoom color from origin layer////////////////////////////////
      var position = findPos(canvasRef.current);
      var x = e.pageX - position.x;
      var y = e.pageY - position.y;
      const { screenX, screenY } = e;
      setZoomTop(screenY - 2 * zoomWidth);
      setZoomLeft(screenX - zoomWidth);
      let c = canvasRef.current.getContext("2d");
      // let c = e.currentTarget.getContext('2d');
      let p = c?.getImageData(x, y, 1, 1).data;
      setZoomColor("#" + rgbToHex(p[0], p[1], p[2]));

      if (props.tab == "Paint") {
        if (isZooming) {
          if (!props.isMobile) {
            setZoomVisible(true);
          }
        }

        // position = findPos(overlayCanvasRef.current);
        let c1 = tmpCanvasRef.current.getContext("2d");
        let tmp = c1?.getImageData(0, 0, c1.canvas.width, c1.canvas.height);
        let p1 = selectCanvasRef.current
          .getContext("2d")
          .getImageData(x, y, 1, 1).data;

        let imd = new ImageData(
          new Uint8ClampedArray(gEmptyData.data),
          gEmptyData.width,
          gEmptyData.height
        );
        //////////////display selected segment of wall/////////////////
        //////////////////if not the white color////////////

        // if(p[0] == p1[0] && p[1] == p1[1] && p[2] == p1[2]){
        for (let i = 0; i < tmp.data.length; i += 24) {
          if (
            tmp.data[i] == p1[0] &&
            tmp.data[i + 1] == p1[1] &&
            tmp.data[i + 2] == p1[2] /* && gOverData.data[i + 3] == p1[3]*/
          ) {
            for (let j = -4; j < 5; j += 4) {
              imd.data[i + j] = 0;
              imd.data[i + j + 1] = 0;
              imd.data[i + j + 2] = 255;
              if (p[0] + p[1] + p[2] + p[3] != 0)
                //+ p1[3]
                imd.data[i + j + 3] = 255;
              else imd.data[i + j + 3] = 0;
            }
          }
        }

        // }

        if (isZooming) {
          overlayCanvasRef.current.getContext("2d").putImageData(imd, 0, 0);
        }
      } else if (props.tab == "Edit") {
        setZoomVisible(true);
      } else if (props.tab == "Erase") {
        setZoomVisible(false);
      }
    }
  }

  function handleMouseLeave() {
    setZoomVisible(false);

    let c1 = overlayCanvasRef.current.getContext("2d");
    c1.putImageData(gEmptyData, 0, 0);
  }

  function handleMouseDown(e) {
    if (e) {
      var position = findPos(canvasRef.current);
      var x = e.pageX - position.x;
      var y = e.pageY - position.y;
      if (props.tab == "Paint") {
        // var repl = [
        //     Math.round(Math.random() * 255),
        //     Math.round(Math.random() * 255),
        //     Math.round(Math.random() * 255),
        //     255
        // ];
        var threshold = 0;
        var erase;

        let c1 = tmpCanvasRef.current.getContext("2d");
        let tmp = c1?.getImageData(x, y, 1, 1).data;
        console.log(tmp);

        if (props.selectedColor) {
          props.selectedColorProps.map((data) => {
            if (data.color_code == "#" + rgbToHex(tmp[0], tmp[1], tmp[2])) {
              data.isUsed = false;
            }
            if (data.color_code == props.selectedColor) {
              data.isUsed = true;
            }
          });
        } else {
          props.selectedColorProps[0].isUsed = true;
        }

        // {props.forUsedColor[`${tmp}`] = hexToRgb(color);}
        // props.setForUsedColor(props.forUsedColor);

        erase = imageData.floodfill([x, y], threshold, [
          ...hexToRgb(
            !props.selectedColor
              ? props.selectedColorProps[0].color_code
              : props.selectedColor
          ),
          255,
        ]);
        if (erase) erasePos = [...erasePos, [...erase, 1]];
        erasePos = Object.assign([], erasePos);
        setErasePos(erasePos);
        setSegData(imageData.segData);
        // let im = new ImageData1(cxt_tmpCanvas,cxt_img, imgWidth, imgHeight, imageData.segData);
        setImageData(imageData);
        imageData.updateCanvas();

        setWorkTemp([
          ...workTemp,
          {
            main_imd: canvasRef.current
              .getContext("2d")
              .getImageData(0, 0, imgWidth, imgHeight),
            imd: tmpCanvasRef.current
              .getContext("2d")
              .getImageData(0, 0, imgWidth, imgHeight),
            area: areaPos,
            erase: erasePos,
            type: "Paint",
          },
        ]);
        setCurrentState(currentState + 1);
        props.setResultData(
          canvasRef.current
            .getContext("2d")
            .getImageData(0, 0, imgWidth, imgHeight)
        );
      } else if (props.tab == "Edit") {
        areaPos = [...areaPos, { x: x, y: y, no: areaPos.length + 1 }];
        setAreaPos(areaPos);
        setWorkTemp([
          ...workTemp,
          {
            main_imd: canvasRef.current
              .getContext("2d")
              .getImageData(0, 0, imgWidth, imgHeight),
            imd: tmpCanvasRef.current
              .getContext("2d")
              .getImageData(0, 0, imgWidth, imgHeight),
            area: areaPos,
            erase: erasePos,
            type: "Edit",
          },
        ]);
        setCurrentState(currentState + 1);
      } else if (props.tab == "Erase") {
      }
    }
  }

  function onChangeRange1(e) {
    let canvas = canvasRef.current;
    let cxt_img = canvas.getContext("2d");

    cxt_img.putImageData(props.uploadData[0], 0, 0);
    let src = cv.imread(canvas);
    cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);
    // You can try more different parameters
    // cv.equalizeHist(src, src);
    // let tileGridSize = new cv.Size(8, 8);
    // // You can try more different parameters
    // let clahe = new cv.CLAHE(40, tileGridSize);
    // clahe.apply(src, src);
    setFirst(e.target.value);
    cv.Canny(src, src, parseInt(e.target.value), parseInt(second), 3, false);

    cv.Laplacian(src, src, cv.CV_8U, 1, 1, 0, cv.BORDER_DEFAULT);
    // cv.Canny(src, dst, 50,100, 3, false);
    console.log(first + "  " + second);
    cv.imshow(tmpCanvasRef.current, src);
    src.delete();
  }
  function onChangeRange2(e) {
    let canvas = canvasRef.current;
    let cxt_img = canvas.getContext("2d");

    cxt_img.putImageData(props.uploadData[0], 0, 0);
    let src = cv.imread(canvas);
    cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);
    // You can try more different parameters
    // cv.equalizeHist(src, src);
    // let tileGridSize = new cv.Size(8, 8);
    // // You can try more different parameters
    // let clahe = new cv.CLAHE(40, tileGridSize);
    // clahe.apply(src, src);
    setSecond(e.target.value);
    cv.Canny(src, src, parseInt(first), parseInt(e.target.value), 3, false);

    cv.Laplacian(src, src, cv.CV_8U, 1, 1, 0, cv.BORDER_DEFAULT);
    // cv.Canny(src, dst, 50,100, 3, false);
    console.log(first + "  " + second);
    cv.imshow(tmpCanvasRef.current, src);
    src.delete();
  }

  function rgbToHex(r, g, b) {
    if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
    return ((r << 16) | (g << 8) | b).toString(16);
  }

  function hexToRgb(hex) {
    hex = hex.replace("#", "");
    var aRgbHex = hex.match(/.{1,2}/g);
    var aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16),
    ];
    return aRgb;
  }

  function findPos(obj) {
    var current_left = 0,
      current_top = 0;
    if (obj.offsetParent) {
      do {
        current_left += obj.offsetLeft;
        current_top += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return { x: current_left, y: current_top };
    }
    return undefined;
  }

  function dragEnd(e, i) {
    var position = findPos(canvasRef.current);
    var x = e.pageX - position.x;
    var y = e.pageY - position.y;
    areaPos[i].x = x;
    areaPos[i].y = y;
    setAreaPos(areaPos);
    console.log(x);
    console.log(y);
    console.log(i);
  }

  function removeLine(e, i) {
    areaPos.splice(i - 1, 2);
    setAreaPos(areaPos);
    setWorkTemp([
      ...workTemp,
      {
        main_imd: canvasRef.current
          .getContext("2d")
          .getImageData(0, 0, imgWidth, imgHeight),
        imd: tmpCanvasRef.current
          .getContext("2d")
          .getImageData(0, 0, imgWidth, imgHeight),
        area: areaPos,
        erase: erasePos,
        type: "Edit",
      },
    ]);
    setCurrentState(currentState + 1);
  }

  function removeErase(e, i) {
    var t = JSON.parse(JSON.stringify(erasePos));

    t[i][2] = 0;
    // erasePos.splice(i,1);

    var c = canvasRef.current
      .getContext("2d")
      .getImageData(0, 0, imgWidth, imgHeight);
    var d = props.uploadData[0];
    for (let j = 0; j < imageData.segData[i + 1].data.length; j += 4) {
      if (
        imageData.segData[i + 1].data[j] +
          imageData.segData[i + 1].data[j + 1] +
          imageData.segData[i + 1].data[j + 2] !=
        0
      ) {
        c.data[j] = d.data[j];
        c.data[j + 1] = d.data[j + 1];
        c.data[j + 2] = d.data[j + 2];
        c.data[j + 3] = d.data[j + 3];
      }
    }
    canvasRef.current.getContext("2d").putImageData(c, 0, 0);
    setWorkTemp([
      ...workTemp,
      {
        main_imd: canvasRef.current
          .getContext("2d")
          .getImageData(0, 0, imgWidth, imgHeight),
        imd: tmpCanvasRef.current
          .getContext("2d")
          .getImageData(0, 0, imgWidth, imgHeight),
        area: areaPos,
        erase: t,
        type: "Erase",
      },
    ]);
    setCurrentState(currentState + 1);
    setErasePos(t);
  }

  return (
    <>
      {/* <input type="range" value={first} onChange={onChangeRange1} max="255" min="0" />
          <input type="range" value={second} onChange={onChangeRange2} max="255" min="0" /> */}
      <canvas ref={canvasRef} />
      <canvas
        ref={zoomRef}
        width={zoomWidth}
        height={zoomWidth}
        style={{
          position: "fixed",
          zIndex: "20",
          top: zoomTop,
          left: zoomLeft,
          backgroundColor: zoomColor,
          borderRadius: "50%",
          visibility: zoomVisible == true ? "visible" : "hidden",
        }}
      />
      <canvas
        ref={overlayCanvasRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        // onTouchStart={handleMouseMove}
        // onTouchEnd={handleMouseLeave}
        onClick={handleMouseDown}
        style={{
          maxWidth: "100%",
          position: "absolute",
          transform: `translateX(-${props.width}px)`,
          zIndex: "10",
          visibility: "visible",
        }}
      />

      <canvas ref={tmpCanvasRef} style={{ display: "none" }} />
      <canvas ref={selectCanvasRef} style={{ display: "none" }} />

      <div>
        {areaPos?.length > 0 &&
          areaVisible &&
          areaPos.map((data, i) => (
            <div
              key={`area${data.no}`}
              draggable
              onDragEnd={(e) => dragEnd(e, i)}
              value={i}
              className="PaintToolEdgeMarker"
              style={{
                left: forceLeft + data.x,
                top: data.y,
                backgroundColor: "rgb(255, 255, 255)",
              }}
            ></div>
          ))}
        {areaPos?.length > 0 &&
          areaVisible &&
          areaPos.map(
            (data, i) =>
              i % 2 == 1 && (
                <div
                  key={`edge${i}`}
                  className="PaintToolEdgeLine"
                  style={{
                    left: forceLeft + areaPos[i - 1].x + 10,
                    top: areaPos[i - 1].y + 10,
                    width: Math.sqrt(
                      (data.y - areaPos[i - 1].y) *
                        (data.y - areaPos[i - 1].y) +
                        (data.x - areaPos[i - 1].x) *
                          (data.x - areaPos[i - 1].x)
                    ),
                    transform: `rotate(${
                      Math.atan2(
                        data.y - areaPos[i - 1].y,
                        data.x - areaPos[i - 1].x
                      ) *
                      (180 / Math.PI)
                    }deg)`,
                  }}
                ></div>
              )
          )}
        {areaPos?.length > 0 &&
          areaVisible &&
          areaPos.map(
            (data, i) =>
              i % 2 == 1 && (
                <div
                  key={`trash${i}`}
                  onClick={(e) => removeLine(e, i)}
                  className="PaintToolEdgeRemove"
                  style={{
                    left: forceLeft + (data.x + areaPos[i - 1].x) / 2,
                    top: (data.y + areaPos[i - 1].y) / 2,
                  }}
                >
                  <span
                    className="fas fa-trash"
                    style={{ color: "white" }}
                  ></span>
                </div>
              )
          )}
        {erasePos?.length > 0 &&
          eraseVisible &&
          erasePos.map(
            (data, i) =>
              data[2] == 1 && (
                <div
                  key={`erase${i}`}
                  onClick={(e) => removeErase(e, i)}
                  className="PaintToolMarkerEraser IsDark"
                  style={{
                    left: forceLeft + data[0],
                    top: data[1],
                    backgroundColor: "rgb(91, 76, 68)",
                    zIndex: "100",
                  }}
                >
                  <span className="fas fa-tint-slash"></span>
                </div>
              )
          )}
      </div>
    </>
  );
});
// style = "position:fixed; top:0; left:0; background-color:red;"
export default UploadCanvas;
