import React from "react";
import { Col, Row } from "react-bootstrap";
import PanelCommonToolbar from "../panel/compnents/PanelCommonToolbar";
import PanelThree from "../panel/PanelThree";
import useTabStore from "../../../store/tab_store";
import useOrderStore from "../../../store/order_store";
import useColorStore from "../../../store/color_store";
import useCategoryStore from "../../../store/category_store";
import PanelCommonToolbarMobile from "../panel/compnents/PanelCommonToolbarMobile";

function StepTwo() {
  const isMobile = useTabStore((state) => state.isMobile);
  const onNext = useTabStore((state) => state.chnageIndex);
  const onCreate = useOrderStore((state) => state.createOrder);
  const colors = useColorStore((state) => state.subColorCategories);
  const currentColors = useColorStore((state) => state.currentColors);
  const categoryColor = useColorStore((state) => state.currentCategory);
  const currentImage = useCategoryStore((state) => state.currentSubCategory);
  const category = useCategoryStore((state) => state.currentCategory);

  return (
    <div className="panel-three">
      <Row>
        <Col>
          <Row>
            <Col>
              {isMobile ? (
                <PanelCommonToolbarMobile
                  title="Select your favorite shades"
                  nextBtnText="Visualize"
                  onNext={() => {
                    onNext(3);
                    onCreate({
                      colors: currentColors,
                      image: currentImage,
                      category: category,
                      colorCategory: categoryColor,
                      selectedColor:
                        currentColors.length > 0 ? currentColors[0] : colors[0],
                    });
                  }}
                />
              ) : (
                <PanelCommonToolbar
                  title="Select your favorite shades"
                  nextBtnText="Visualize"
                  onNext={() => {
                    onNext(3);
                    onCreate({
                      colors: currentColors,
                      image: currentImage,
                      category: category,
                      colorCategory: categoryColor,
                      selectedColor:
                        currentColors.length > 0 ? currentColors[0] : colors[0],
                    });
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <PanelThree />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default StepTwo;
