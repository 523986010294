const CategoryList = [
  {
    id: 1,
    title: "All Rooms",
    group: "allrooms",
  },
  {
    id: 2,
    title: "Dining Room",
    group: "dining",
  },
  {
    id: 3,
    title: "Living Room",
    group: "living",
  },
  {
    id: 4,
    title: "Bedroom",
    group: "bed",
  },
  {
    id: 5,
    title: "Kitchen",
    group: "kitchen",
  },
  {
    id: 6,
    title: "Bathroom",
    group: "bathroom",
  },
  {
    id: 7,
    title: "Exterior",
    group: "exterior",
  },
  {
    id: 8,
    title: "Commercial",
    group: "commercial",
  },
  {
    id: 9,
    title: "Industrial",
    group: "industrial",
  },
];

export default CategoryList;