import { create } from "zustand";

import { devtools, persist } from "zustand/middleware";

const previewStore = (set) => ({
  isEdit: false,
  isUpload: false,
  onEditImage: (value) => set((state) => ({ isEdit: value })),
  onUpload: (value) => set((state) => ({ isUpload: value })),
});

const usePreviewStore = create(devtools(previewStore));

export default usePreviewStore;
