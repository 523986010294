import React from "react";

function ImageToolBtn({ title, icon, onPress }) {
  return (
    <div className="panel-four-tool-btn" onClick={onPress}>
      {icon !== undefined && <>{icon}</>}
      <div className="panel-four-tool-btn-text">{title}</div>
    </div>
  );
}

export default ImageToolBtn;
