import React from "react";
import useColorStore from "../../../../store/color_store";

function PanelThreeColorList() {
  const colorCategories = useColorStore((state) => state.categoriesColor);
  const currentCategory = useColorStore((state) => state.currentCategory);
  const getColorCategory = useColorStore((state) => state.getColorCategory);

  const handler = (color) => {
    getColorCategory(color);
  };

  return (
    <div className="color-category-list-section">
      {colorCategories.map((color) => (
        <div>
          <div
            onClick={() => {
              handler(color);
            }}
            key={color.id}
            className={
              currentCategory.id === color.id
                ? "color-category-item active"
                : "color-category-item"
            }
            
          >
            <div
              className="color-seaction"
              style={{
                background: color.color_code,
              }}
            >
              <img
                className="color_frame"
                alt="color frame"
                src={require("../../../../assets/images/colorbox.png")}
              />

              <span
                className={
                  color.color_code === "#ffffff"
                    ? "checkmark text-dark"
                    : "checkmark"
                }
              >
                <i className="far fa-check"></i>
              </span>
            </div>
            <p className="color-category-item-title">{color.title}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PanelThreeColorList;
